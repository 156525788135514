import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Card, Col, Row, Spin, Tabs } from "antd";

import { IAppComponentProps } from "../../components";
import SurveyConnectivityContact from "./surveyConnectivity/SurveyConnectivityContact";
import SurveyConnectivityAS2 from "./surveyConnectivity/SurveyConnectivityAS2";
import SurveyConnectivitySMTP from "./surveyConnectivity/SurveyConnectivitySMTP";
import SurveyConnectivityOFTP2 from "./surveyConnectivity/SurveyConnectivityOFTP2";
import { useIntl } from "react-intl";
import SurveyConnectivityTestFile from "./surveyConnectivity/SurveyConnectivityTestFile";
import { getSurveyVerifyData } from "./SurveyVerifySubmit/SurveyVerifyAndSubmitService";
import {
  getSurveyVerifyReport,
  getSurveyVerifyReport_getSurveyVerifyReport_channelTypes,
  getSurveyVerifyReport_getSurveyVerifyReport_formatTypes,
  getSurveyVerifyReport_getSurveyVerifyReport_portions
} from "./SurveyVerifySubmit/__generated__/getSurveyVerifyReport";
import { setGroupId } from "../../utils";
import SurveyConnectivityInfoVDAForm from "./surveyConnectivity/SurveyConnectivityVDA";
import {SurveyConnectivityPeppol} from "./surveyConnectivity/SurveyConnectivityPeppol";
import {StateContainer} from "./utilities/StateContainer";
import { useAppDispatch, useAppSelector } from "../../main/hooks";
import { resetSurveyDataLoading, setSurveyDataLoading } from "./IStoredSurveySlice";

type ISurveyConnectivityInfoStepProps = {
  next?: any;
  prev?: any;
  groupId: string;
  surveyMasterData?: any;
  onStatusUpdate?: ()=>void;
  stateContainer?: StateContainer;
} & IAppComponentProps;

const SurveyConnectivityInfoStep: FunctionComponent<ISurveyConnectivityInfoStepProps> = (props) => {
  const dispatch = useAppDispatch();
  const { TabPane } = Tabs;
  const intl = useIntl();
  const [channelTypes, setChannelTypes] = useState<getSurveyVerifyReport_getSurveyVerifyReport_channelTypes[]>();
  const [formatTypes, setFormatTypes] = useState<getSurveyVerifyReport_getSurveyVerifyReport_formatTypes[]>();
  // const [loading, isLoading] = useState<boolean>(false);

  setGroupId(props.groupId);

  const hasChannel = (channels: getSurveyVerifyReport_getSurveyVerifyReport_channelTypes[], channelType: String): boolean => {
    console.error(channels);
    if (channels === undefined || channels === null) {
      return false;
    }
    return channels.filter(obj => {
      return obj.channelType === channelType;
    }).length > 0;
  };

  const hasVDAFormats = (formats: getSurveyVerifyReport_getSurveyVerifyReport_formatTypes[]): boolean => {
    console.error(formats);
    if (formats === undefined || formats === null) {
      return false;
    }
    return formats.filter(obj => {
      return obj.documentFormat.indexOf("VDA") > -1;
    }).length > 0;
  };

  const canViewTestFiles = ()=> {
    console.log(`partner type: ${localStorage.getItem("partner-type") }`);
    if (localStorage.getItem("partner-type") === "V") {
      let channelTypesOk=undefined;
      console.log("Channel types");
      console.dir(channelTypes)
      if (channelTypes) {
        channelTypesOk = channelTypes.find(item=>item?.processCodeId===20 && item?.incomingId !== null)
      }
      console.log(`channelTypesOk:`);
      console.dir(channelTypesOk)
       return channelTypesOk
    }

    return false;
  }

  function canViewPeppol():boolean {
    const containsPeppolChannel = channelTypes?.find(item=>item?.channelType?.toUpperCase() === "PEPPOL") !== undefined;
    // const hasGroupId = props.groupId !== undefined && props.groupId !== null && props.groupId !== "";
    // const result = containsPeppolChannel && hasGroupId;

    return containsPeppolChannel;
  }

  useEffect(() => {
    // isLoading(true);
    dispatch(setSurveyDataLoading(true));
    getSurveyVerifyData(props.groupId)
      .then((response: getSurveyVerifyReport) => {
        setChannelTypes(response.getSurveyVerifyReport.channelTypes);
        setFormatTypes(response.getSurveyVerifyReport.formatTypes);
        console.error(response);
        // isLoading(false);
      })
      .catch(() => {
        dispatch(resetSurveyDataLoading(true));
      })
      .finally(() => {
        dispatch(setSurveyDataLoading(false));
      });
  }, []);

  return (
    <>
      <Card title={intl.formatMessage({
        id: "survey-connectivityInfo-title",
        defaultMessage: "Connectivity Info"
      })}
        size={"small"} className="ml-10">
        {/* <Spin spinning={surveyState.survey.surveyDataLoading > 0} size={"large"}> */}
          <Row gutter={8}>
            <Col span={24}>
              <Tabs defaultActiveKey={"1"} className="users-tabs ml20">
                <TabPane tab={intl.formatMessage({
                  id: "survey-connectivityInfo-technicalContactDetails-title",
                  defaultMessage: "Technical Contact Details"
                })}
                         key="1" className="pl-5">
                  <SurveyConnectivityContact intl={props.intl} currentUser={props.currentUser}
                                             distinctEntitlements={props.distinctEntitlements}
                                             groupId={props.groupId} onStatusUpdate={props.onStatusUpdate}
                  />
                </TabPane>
                {hasChannel(channelTypes, "AS2") &&
                <TabPane tab={intl.formatMessage({
                  id: "survey-connectivityInfo-as2-title",
                  defaultMessage: "AS2"
                })}
                         key="2" className="pl-5">
                  <SurveyConnectivityAS2 intl={props.intl} currentUser={props.currentUser} distinctEntitlements={props.distinctEntitlements}
                                         groupId={props.groupId} onStatusUpdate={props.onStatusUpdate}
                  />
                </TabPane>
                }
                {hasChannel(channelTypes, "SMTP") &&
                <TabPane tab={intl.formatMessage({
                  id: "survey-connectivityInfo-smtp-title",
                  defaultMessage: "SMTP"
                })}
                         key="3" className="pl-5">
                  <SurveyConnectivitySMTP intl={props.intl} currentUser={props.currentUser} distinctEntitlements = {props.distinctEntitlements}
                                          groupId={props.groupId} onStatusUpdate={props.onStatusUpdate}
                  />
                </TabPane>
                }
                {hasChannel(channelTypes, "OFTP2") &&
                <TabPane tab={intl.formatMessage({
                  id: "survey-connectivityInfo-oftp2-title",
                  defaultMessage: "OFTP2"
                })}
                         key="4" className="pl-5">
                  <SurveyConnectivityOFTP2 intl={props.intl} currentUser={props.currentUser}
                                           distinctEntitlements={props.distinctEntitlements}
                                           groupId={props.groupId} onStatusUpdate={props.onStatusUpdate}
                  />
                </TabPane>
                }
                {hasVDAFormats(formatTypes) &&
                <TabPane tab={intl.formatMessage({
                  id: "survey-connectivityInfo-vda-title",
                  defaultMessage: "VDA"
                })}
                         key="8" className="pl-5">
                  <SurveyConnectivityInfoVDAForm intl={props.intl} currentUser={props.currentUser}
                                                 distinctEntitlements={props.distinctEntitlements}
                                                 groupId={props.groupId}
                                                 surveyMasterData={props.surveyMasterData}
                                                 onStatusUpdate={props.onStatusUpdate}
                                                 stateContainer={props.stateContainer}
                  />
                </TabPane>
                }
                {canViewTestFiles() &&
                    <TabPane tab={intl.formatMessage({
                      id: "survey-connectivityInfo-test-file-title",
                      defaultMessage: "Test File"
                    })}
                             key="5" className="pl-5">
                      <SurveyConnectivityTestFile
                          intl={props.intl}
                          currentUser={props.currentUser}
                          distinctEntitlements={props.distinctEntitlements}
                          groupId={props.groupId}
                          onStatusUpdate={props.onStatusUpdate}
                      />
                    </TabPane>
                }

                {canViewPeppol() &&
                    <TabPane tab={intl.formatMessage({
                      id: "survey-connectivity-peppol-tab",
                    })}
                             key="6" className="pl-5">
                      <SurveyConnectivityPeppol
                          intl={props.intl}
                          currentUser={props.currentUser}
                          distinctEntitlements={props.distinctEntitlements}
                          groupId={props.groupId}
                          onStatusUpdate={props.onStatusUpdate}
                      />
                    </TabPane>
                }
              </Tabs>
            </Col>
          </Row>

      </Card>

    </>
  );

};

export default SurveyConnectivityInfoStep;
