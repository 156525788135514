import { useQuery } from '@apollo/client';

import {
    CloseCircleOutlined,
    DownloadOutlined,
    FileSearchOutlined,
    ReloadOutlined,
    SearchOutlined,
} from '@ant-design/icons';

import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Spin } from 'antd';
import React from 'react';
import { QUERY_INVOICE_FILTERS } from './invotrackQueries';
import { EIMMonitoringGridRequest } from './interfaces';
import { useIntl } from 'react-intl';
import { IAppComponentProps } from '../../components';

type IProps = {
    searchModel?: EIMMonitoringGridRequest;
    onSearch: any;
    onReset: any;
    onReload: any;
    onDownload: any;
    onCount: any;
} & IAppComponentProps;

const InvoiceStatusSearchForm: React.FC<IProps> = (props) => {
    const { onSearch, onReset, onDownload, onReload, onCount } = props;
    const intl = useIntl();
    const [form] = Form.useForm();
    const { Option } = Select;
    const formItemLayout = {
        labelCol: {
            xs: { span: 22 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 22 },
            sm: { span: 12 },
        },
    };

    const { loading, data, error } = useQuery(QUERY_INVOICE_FILTERS, {
        variables: { optionQuery: 'all.countries.en' },
        fetchPolicy: 'network-only',
    });

    return (
        <Spin spinning={loading} size={'large'}>
            <Form {...formItemLayout} onFinish={() => onSearch(form.getFieldsValue())} form={form}>
                <Row gutter={20}>
                    <Col span={24} style={{ paddingBottom: '10px', textAlign: 'left' }}>
                        <Button type={'default'} htmlType="submit" size={'large'}>
                            <FileSearchOutlined style={{ fontSize: '25px', color: '#117b9a' }} />
                            <b> {intl.formatMessage({ id: 'invoiceStatusSearchForm.search' })}</b>
                        </Button>

                        <Button
                            type={'default'}
                            style={{ marginLeft: 8 }}
                            onClick={() => onReload(form.getFieldsValue())}
                            size={'large'}
                        >
                            <ReloadOutlined style={{ fontSize: '25px', color: '#05a233' }} />{' '}
                            <b>{intl.formatMessage({ id: 'invoiceStatusSearchForm.reload' })} </b>
                        </Button>

                        <Button
                            type={'default'}
                            style={{ marginLeft: 8 }}
                            onClick={() => {
                                form.resetFields();
                                onReset();
                            }}
                            size={'large'}
                        >
                            <CloseCircleOutlined style={{ fontSize: '25px', color: '#e9190b' }} />{' '}
                            <b>{intl.formatMessage({ id: 'invoiceStatusSearchForm.clear' })} </b>
                        </Button>
                        <Button
                            type={'default'}
                            style={{ marginLeft: 8 }}
                            size={'large'}
                            onClick={() => onDownload(form.getFieldsValue())}
                        >
                            <DownloadOutlined style={{ fontSize: '25px', color: '#05a233' }} />{' '}
                            <b>{intl.formatMessage({ id: 'invoiceStatusSearchForm.download' })} </b>
                        </Button>

                        <Button
                            type={'default'}
                            style={{ marginLeft: 8 }}
                            onClick={() => onCount(form.getFieldsValue())}
                            size={'large'}
                        >
                            <SearchOutlined style={{ fontSize: '25px', color: '#06092b' }} />{' '}
                            <b>{intl.formatMessage({ id: 'invoiceStatusSearchForm.count' })} </b>
                        </Button>
                        <Button id={'btnTotalInvoiceSteps'} type={'link'} style={{ marginLeft: 8 }} />
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col span={24} style={{ paddingTop: 6 }}>
                        <Card
                            title={intl.formatMessage({ id: 'invoiceStatusSearchForm.searchmask' })}
                            size="small"
                            bordered={true}
                            bodyStyle={{ background: '#f5f7f7' }}
                        >
                            <Row gutter={20}>
                                <Col span={6}>
                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'invoiceStatusSearchForm.fromDate',
                                        })}
                                        required={true}
                                        name={'fromDate'}
                                        rules={[
                                            {
                                                required: true,
                                                message: intl.formatMessage({
                                                    id: 'invoiceStatusSearchForm.fromDate_required',
                                                    defaultMessage: 'From Date is required',
                                                }),
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            format={'DD.MM.YYYY'}
                                            placeholder={intl.formatMessage({
                                                id: 'invoiceStatusSearchForm.fromDateHint',
                                            })}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'invoiceStatusSearchForm.toDate',
                                        })}
                                        required={true}
                                        name={'toDate'}
                                        rules={[
                                            {
                                                required: true,
                                                message: intl.formatMessage({
                                                    id: 'invoiceStatusSearchForm.toDate_required',
                                                    defaultMessage: 'To Date is required',
                                                }),
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            format={'DD.MM.YYYY'}
                                            placeholder={intl.formatMessage({
                                                id: 'invoiceStatusSearchForm.toDateHint',
                                            })}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={4}>
                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'invoiceStatusSearchForm.partnerNo',
                                        })}
                                        name={'partnerno'}
                                    >
                                        <Input
                                            placeholder={intl.formatMessage({
                                                id: 'invoiceStatusSearchForm.partnerNoHint',
                                            })}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'invoiceStatusSearchForm.company',
                                        })}
                                        name={'company'}
                                    >
                                        <Select
                                            mode="multiple"
                                            allowClear={true}
                                            placeholder={intl.formatMessage({
                                                id: 'invoiceStatusSearchForm.companyCodeHint',
                                            })}
                                        >
                                            {loading ? (
                                                <Option key="searchCompCodes" value="">
                                                    ""
                                                </Option>
                                            ) : (
                                                data?.getAllMessagesFilters.company.map((item) => (
                                                    <Option key={item.value} value={item.value}>
                                                        {item.value}
                                                    </Option>
                                                ))
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'invoiceStatusSearchForm.uuid',
                                        })}
                                        name={'message_id'}
                                    >
                                        <Input
                                            placeholder={intl.formatMessage({
                                                id: 'invoiceStatusSearchForm.uuidHint',
                                            })}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'invoiceStatusSearchForm.reference',
                                        })}
                                        name={'reference'}
                                    >
                                        <Input
                                            placeholder={intl.formatMessage({
                                                id: 'invoiceStatusSearchForm.referenceHint',
                                            })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'invoiceStatusSearchForm.process',
                                        })}
                                        name={'process'}
                                    >
                                        <Select
                                            mode="multiple"
                                            allowClear={true}
                                            placeholder={intl.formatMessage({
                                                id: 'invoiceStatusSearchForm.processHint',
                                            })}
                                        >
                                            {loading ? (
                                                <Option key="searchCompCodes" value="">
                                                    ""
                                                </Option>
                                            ) : (
                                                (data || {}).getAllMessagesFilters.process.map((item) => (
                                                    <Option key={item.value} value={item.value}>
                                                        {item.value}
                                                    </Option>
                                                ))
                                            )}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'invoiceStatusSearchForm.minterface',
                                        })}
                                        name={'m_interface'}
                                    >
                                        <Select
                                            mode="multiple"
                                            allowClear={true}
                                            placeholder={intl.formatMessage({
                                                id: 'invoiceStatusSearchForm.minterfaceHint',
                                            })}
                                        >
                                            {loading ? (
                                                <Option key="minterface" value="">
                                                    ""
                                                </Option>
                                            ) : (
                                                (data || {}).getAllMessagesFilters.m_interface.map((item) => (
                                                    <Option key={item.value} value={item.value}>
                                                        {item.value}
                                                    </Option>
                                                ))
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={4}>
                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'invoiceStatusSearchForm.status',
                                        })}
                                        name={'status'}
                                    >
                                        <Select
                                            mode="multiple"
                                            allowClear={true}
                                            placeholder={intl.formatMessage({
                                                id: 'invoiceStatusSearchForm.status',
                                            })}
                                        >
                                            {loading ? (
                                                <Option key="searchCompCodes" value="">
                                                    ""
                                                </Option>
                                            ) : (
                                                (data || {}).getAllMessagesFilters.status.map((item) => (
                                                    <Option key={item.value} value={item.value}>
                                                        {item.value}
                                                    </Option>
                                                ))
                                            )}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'invoiceStatusSearchForm.reason',
                                        })}
                                        name={'status_reason'}
                                    >
                                        <Input
                                            placeholder={intl.formatMessage({
                                                id: 'invoiceStatusSearchForm.reasonHint',
                                            })}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default InvoiceStatusSearchForm;
