import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Collapse, DatePicker, Form, Input, message, Modal, Row, Select, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useIntl } from 'react-intl';
import { getI18n } from '../../../utils/Utils';
import { addBannerData, editBannerData } from '../NotificationService';
import dayjs, { Dayjs } from 'dayjs';
import { Radio } from 'antd/lib';
import { modifyBannerTextForDisplay } from '../../../layouts/Banner';
import { IAppComponentProps } from '../../../components';
import {CloseCircleOutlined, ExclamationCircleOutlined} from "@ant-design/icons";

type AddBannerModalProps = {
    isModalVisible: any;
    handleCancel: any;
    bannerValues: any;
    afterClose: any;
    adding: Boolean;
} & IAppComponentProps;

type FormData = {
    bannerId: number;
    bannerShowStart: string;
    bannerShowEnd: string;
    bannerHeadingDe: string;
    bannerHeadingEn: string;
    bannerMessageDe: string;
    bannerMessageEn: string;
    startTime: string;
    endTime: string;
    messageType: string;
};

const AddBannerModal: React.FunctionComponent<AddBannerModalProps> = (props) => {
    type PreviewOptions = { selectedLanguage: string };
    const [form] = Form.useForm();
    const intl = useIntl();
    const [loading, setLoading] = useState<boolean>(false);
    const [showHeader, setShowHeader] = useState<boolean>(false);
    const [preview, setPreview] = useState<PreviewOptions>({ selectedLanguage: 'en' });
    const [messageType, _setMessageType] = useState([
        {
            value: 'warning',
            id: 'warning',
        },
        {
            value: 'info',
            id: 'info',
        },
        {
            value: 'success',
            id: 'success',
        },
        {
            value: 'error',
            id: 'error',
        },
    ]);

    const [_flag, setFlag] = useState<boolean>(false);
    const reload = (_unusedParam) => setFlag((val) => !val);

    const handleSubmit = () => {
        const formatDate = (date: Dayjs) => {
            if (date?.isValid?.()) {
                return date.format('YYYY-MM-DD HH:mmZ');
            }

            return null;
        };

        const formData: FormData = {
            bannerHeadingDe: form.getFieldValue('bannerHeadingDe'),
            bannerHeadingEn: form.getFieldValue('bannerHeadingEn'),
            bannerId: props.adding ? undefined : props.bannerValues?.bannerId,
            bannerMessageDe: form.getFieldValue('bannerMessageDe'),
            bannerMessageEn: form.getFieldValue('bannerMessageEn'),
            bannerShowEnd: formatDate(form.getFieldValue('bannerShowEnd')),
            bannerShowStart: formatDate(form.getFieldValue('bannerShowStart')),
            endTime: formatDate(form.getFieldValue('bannerEndTime')),
            messageType: form.getFieldValue('bannerMessageType'),
            startTime: formatDate(form.getFieldValue('bannerStartTime')),
        };

        setLoading(true);
        props.adding
            ? Promise.resolve(addBannerData(formData))
                  .then(() => {
                      message.success(getI18n('banner-AddDataSuccess', 'Banner Added Successfully', intl));

                      form.resetFields();
                      props.handleCancel();
                      props.afterClose();
                  })
                  .catch((err) => {
                      console.error(err);
                      message.error(getI18n('banner-AddDataFailure', 'Add Banner failed', intl));
                  })
                  .finally(() => {
                      setLoading(false);
                  })
            : Promise.resolve(editBannerData(formData))
                  .then(() => {
                      message.success(getI18n('Banner-EditDataSuccess', 'Banner Edit Successfully', intl));
                      form.resetFields();
                      props.handleCancel();
                      props.afterClose();
                  })
                  .catch((err) => {
                      console.error(err);
                      message.error(getI18n('banner-EditDataFailure', 'Edit Banner Failed', intl));
                  })
                  .finally(() => {
                      setLoading(false);
                  });
    };

    const handleCancelInModal = () => {
        setLoading(false);
        props.handleCancel();
    };

    useEffect(() => {
        if (props.adding) {
            form.resetFields();
        } else {
            form.setFieldsValue({
                bannerShowStart: dayjs(props.bannerValues?.bannerShowStart),
                bannerShowEnd: dayjs(props.bannerValues?.bannerShowEnd),
                bannerStartTime: props.bannerValues?.startTime && dayjs(props.bannerValues?.startTime),
                bannerEndTime: props.bannerValues?.endTime && dayjs(props.bannerValues?.endTime),
                bannerMessageType: props.bannerValues?.messageType,
                bannerHeadingEn: props.bannerValues?.bannerHeadingEn,
                bannerHeadingDe: props.bannerValues?.bannerHeadingDe,
                bannerMessageEn: props.bannerValues?.bannerMessageEn,
                bannerMessageDe: props.bannerValues?.bannerMessageDe,
            });
        }

        reload(null);
    }, [props.isModalVisible]);

    return (
        <>
            <Modal
                title={
                    props.adding
                        ? getI18n('banner-management-modal-Add-Banner', 'Add Banner', intl)
                        : getI18n('banner-management-modal-title-edit-banner', 'Edit Banner', intl)
                }
                afterClose={props.afterClose}
                onCancel={() => handleCancelInModal()}
                open={props.isModalVisible}
                width={'900px'}
                mask={true}
                footer={[
                    <Button key="back" onClick={handleCancelInModal} disabled={loading}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={(_) => form.submit()} disabled={loading}>
                        Submit
                    </Button>,
                ]}
            >
                <Spin spinning={loading} size="large">
                    <Form size={'middle'} layout={'vertical'} form={form} onFinish={handleSubmit}>
                        <Row gutter={24} style={{ alignContent: 'flex-end', marginTop: '50' }} />
                        <Collapse
                            items={[
                                {
                                    label: 'Preview',
                                    children: (
                                        <div>
                                            {!showHeader ?
                                            <Alert
                                                message={
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: modifyBannerTextForDisplay(
                                                                form.getFieldValue(
                                                                    preview.selectedLanguage === 'en'
                                                                        ? 'bannerMessageEn'
                                                                        : 'bannerMessageDe'
                                                                ),
                                                                form.getFieldValue('bannerStartTime'),
                                                                form.getFieldValue('bannerEndTime'),
                                                                props.currentUser
                                                            ),
                                                        }}
                                                    />
                                                }
                                                action={<div>
                                                    <CloseCircleOutlined
                                                        style={{
                                                            fontSize: '16px',
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={_=> setShowHeader(val=>!val)}
                                                    />
                                                </div>}
                                                type={form.getFieldValue('bannerMessageType') || 'info'}
                                                style={{
                                                    marginBottom: '10',
                                                    textAlign: 'center',
                                                    alignItems: 'center',
                                                    height: '82px',
                                                }}
                                            />:
                                                <Alert
                                                    message={
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: modifyBannerTextForDisplay(
                                                                    form.getFieldValue(
                                                                        preview.selectedLanguage === 'en'
                                                                            ? 'bannerHeadingEn'
                                                                            : 'bannerHeadingDe'
                                                                    ),
                                                                    form.getFieldValue('bannerStartTime'),
                                                                    form.getFieldValue('bannerEndTime'),
                                                                    props.currentUser
                                                                ),
                                                            }}
                                                        />
                                                    }
                                                    action={<div>
                                                        <ExclamationCircleOutlined
                                                            style={{
                                                                fontSize: '16px',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={_=> setShowHeader(val=>!val)}
                                                        />
                                                    </div>}
                                                    type={form.getFieldValue('bannerMessageType') || 'info'}
                                                    style={{
                                                        fontFamily: 'BMW Group Condensed',
                                                        textAlign: 'right',
                                                        marginRight: '0',
                                                        marginLeft: 'auto',
                                                        height: '36px'
                                                    }}
                                                />
                                            }

                                            <div>
                                                <div style={{ marginTop: '20px' }}>
                                                    <Radio.Group
                                                        buttonStyle={'solid'}
                                                        style={{ display: 'flex', width: '200px' }}
                                                        onChange={(e) =>
                                                            setPreview({ selectedLanguage: e.target.value })
                                                        }
                                                        options={[
                                                            {
                                                                label: intl.formatMessage({ id: 'english-option' }),
                                                                value: 'en',
                                                            },
                                                            {
                                                                label: intl.formatMessage({ id: 'german-option' }),
                                                                value: 'de',
                                                            },
                                                        ]}
                                                        defaultValue="en"
                                                        optionType={'button'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                        />
                        <Card
                            title={
                                props.adding
                                    ? getI18n('banner-management-modal-Add-Banner-Details', 'Add Banner Details', intl)
                                    : getI18n(
                                          'banner-management-modal-edit-banner-details',
                                          'Edit Banner Details',
                                          intl
                                      )
                            }
                        >
                            <Row gutter={24}>
                                <Form.Item
                                    name={'bannerShowStart'}
                                    key={'bannerShowStart'}
                                    label={getI18n(
                                        'banner-management-modal-banner-show-start',
                                        'Banner Show Start',
                                        intl
                                    )}
                                    rules={[
                                        {
                                            required: true,
                                            message: intl.formatMessage({ id: 'generic-requiredField-message' }),
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value: Dayjs) {
                                                const showEnd = getFieldValue('bannerShowEnd');
                                                if (showEnd && value && value.isAfter(showEnd)) {
                                                    return Promise.reject(new Error('The Banner show start cannot come after the Banner show end'));
                                                }
                                                return Promise.resolve();
                                            },
                                        })
                                    ]}
                                >
                                    <DatePicker
                                        showTime={{ format: 'HH:mm' }}
                                        format={'YYYY-MM-DD HH:mm'}
                                        placeholder={getI18n(
                                            'banner-management-modal-banner-show-start',
                                            'Banner Show Start',
                                            intl
                                        )}
                                        allowClear={true}
                                        onChange={reload}
                                        data-testid={'bannerShowStart'}
                                        id={'bannerShowStart'}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={'bannerShowEnd'}
                                    key={'bannerShowEnd'}
                                    label={getI18n(
                                        'banner-management-add-modal-banner-show-end',
                                        'Banner Show End',
                                        intl
                                    )}
                                    rules={[
                                        {
                                            required: true,
                                            message: intl.formatMessage({ id: 'generic-requiredField-message' }),
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value: Dayjs) {
                                                const showStart = getFieldValue('bannerShowStart');
                                                if (showStart && value && value.isBefore(showStart)) {
                                                    return Promise.reject(new Error('The Banner show end cannot come before the Banner show start'));
                                                }
                                                return Promise.resolve();
                                            },
                                        })
                                    ]}
                                >
                                    <DatePicker
                                        showTime={{ format: 'HH:mm' }}
                                        format={'YYYY-MM-DD HH:mm'}
                                        placeholder={getI18n(
                                            'banner-management-modal-banner-show-end',
                                            'Banner Show End',
                                            intl
                                        )}
                                        allowClear={true}
                                        onChange={reload}
                                        data-testid={'bannerShowEnd'}
                                        id={'bannerShowEnd'}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={'bannerMessageType'}
                                    key={'bannerMessageType'}
                                    label={getI18n('banner-management-add-modal-message-type', 'Message Type', intl)}
                                    rules={[
                                        {
                                            required: true,
                                            message: intl.formatMessage({ id: 'generic-requiredField-message' }),
                                        },
                                    ]}
                                >
                                    <Select
                                        size="middle"
                                        style={{ width: 180 }}
                                        options={messageType}
                                        onChange={reload}
                                    />
                                </Form.Item>
                            </Row>
                        </Card>
                        <Card
                            title={
                                props.adding
                                    ? getI18n('banner-management-modal-add-banner-message', 'Add Banner Message', intl)
                                    : getI18n(
                                          'banner-management-modal-edit-banner-message',
                                          'Edit Banner Message',
                                          intl
                                      )
                            }
                        >
                            <Row>
                                <Form.Item
                                    name={'bannerHeadingEn'}
                                    key={'bannerHeadingEn'}
                                    rules={[
                                        {
                                            required: true,
                                            message: intl.formatMessage({ id: 'generic-requiredField-message' }),
                                        },
                                    ]}
                                    label={getI18n(
                                        'banner-management-add-modal-label-banner-heading-english',
                                        'Banner Heading English',
                                        intl
                                    )}
                                >
                                    <Input
                                        placeholder={getI18n(
                                            'banner-management-modal-banner-banner-heading-english',
                                            'Banner Heading English',
                                            intl
                                        )}
                                        id={'bannerHeadingEn'}
                                        style={{ width: 300, height: 40 }}
                                        allowClear={true}
                                        onChange={reload}
                                        bordered={true}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={'bannerHeadingDe'}
                                    key={'bannerHeadingDe'}
                                    rules={[
                                        {
                                            required: true,
                                            message: intl.formatMessage({ id: 'generic-requiredField-message' }),
                                        },
                                    ]}
                                    label={getI18n(
                                        'banner-management-add-modal-label-banner-heading-german',
                                        'Banner Heading German',
                                        intl
                                    )}
                                >
                                    <Input
                                        placeholder={getI18n(
                                            'banner-management-modal-banner-banner-heading-german',
                                            'Banner Heading German',
                                            intl
                                        )}
                                        id={'bannerHeadingDe'}
                                        style={{ width: 300, height: 40 }}
                                        allowClear={true}
                                        onChange={reload}
                                    />
                                </Form.Item>
                            </Row>
                            <Row>
                                <Form.Item
                                    name={'bannerMessageEn'}
                                    key={'bannerMessageEn'}
                                    rules={[
                                        {
                                            required: true,
                                            message: intl.formatMessage({ id: 'generic-requiredField-message' }),
                                        },
                                    ]}
                                    label={getI18n(
                                        'banner-management-add-modal-label-banner-message-english',
                                        'Banner Message English',
                                        intl
                                    )}
                                >
                                    <TextArea
                                        placeholder={`${getI18n(
                                            'banner-message-headerText-line2',
                                            '',
                                            intl
                                        )}`}
                                        id={'bannerMessageEn'}
                                        style={{ width: 700 }}
                                        rows={4}
                                        allowClear={true}
                                        onChange={reload}
                                    />
                                </Form.Item>
                            </Row>
                            <Row>
                                <Form.Item
                                    name={'bannerMessageDe'}
                                    key={'bannerMessageDe'}
                                    rules={[
                                        {
                                            required: true,
                                            message: intl.formatMessage({ id: 'generic-requiredField-message' }),
                                        },
                                    ]}
                                    label={getI18n(
                                        'banner-management-add-modal-label-banner-message-german',
                                        'Banner Message German',
                                        intl
                                    )}
                                >
                                    <TextArea
                                        placeholder={getI18n(
                                            'banner-message-headerText-line2',
                                            '',
                                            intl
                                        )}
                                        id={'bannerMessageDe'}
                                        style={{ width: 700, marginTop: 10 }}
                                        rows={4}
                                        allowClear={true}
                                        onChange={reload}
                                    />
                                </Form.Item>
                            </Row>
                        </Card>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
};

export default AddBannerModal;
