import React, { useEffect } from 'react';
import {
    DropDownSelectionMode,
    ExternalStateUpdateCallback,
    InfoPortalAgGridColumnDef,
    InfoPortalColumnDef,
    InfoPortalGridService,
    InfoPortalReport,
    InfoPortalSearchProperties,
    IValidator,
    OnChangeListener,
    ReportViewConfig,
    stringToDayjs_yyyy_MM_dd,
    utcDateToFormatTo_yyyy_MM_dd_string,
    Validator,
} from '../../../InfoPortalInterfaces';
import { PurchaseOrderNumber } from '../../InputWithOperator';
import { Card, FormInstance } from 'antd';
import {
    BusinessAreaListSelection,
    CompanySelection,
    DeliveredStatusSelection,
    descriptionFilter,
    InvoiceStatusSelection,
    InvoicingPartySelection,
    PartnerSelection,
    PlantInfoSelection,
    SettlementTypeSelection,
} from '../../InfoPortalDropdowns';
import styles from '../../styling/infoportal.module.css';
import { PurchaseOrderFromToSelection } from '../../FromToDateSelection';
import { RuleSet } from '../../../../e-cap/util/RuleResultCache';
import { IntlShape } from 'react-intl/src/types';
import { retrieveSR1Report } from '../../../services/ReportSearch';
import { PortalUser } from '../../../../../components';
import {
    convertToUserSpecifiedNumericFormat,
    dateComparatorUsingUserPreferences,
    getDateFormatOrDefault,
    numericComparatorUsingUserPreferences,
} from '../../../../users/UserFormatPreferenceServices';
import {
    filterParamAmountFormattedAsStringUsingPreferences,
    filterParamWithUserDateFormatComparator
} from "../../grid/GridUtil";


function validatator(form: FormInstance<any>): Validator {
    const validator: IValidator = {
        fetchRule(fieldName: string): RuleSet {
            const result = {
                required: false,
                picture: '',
                readOnly: false,
                hidden: false,
                formatMessage: null,
                infoMessage: null,
            };
            if (fieldName === 'company') {
                result.required = true;
            }
            if (fieldName === 'partner') {
                result.required = true;
            }
            if (fieldName === 'purchaseOrderDate') {
                result.required = true;
            }
            return result;
        },
    };
    return new Validator(validator);
}

function PurchaseOrderSimpleSearch({
                                     currentUser,
                                     intl,
                                     reportEntitlement,
                                     distinctEntitlements,
                                     initialization,
                                     form,
                                     initialValuesCache,
                                     rerenderDependentFields
                                   }: InfoPortalSearchProperties) {
  const validator = validatator(form);
  const partnerSelectionUpdate = new ExternalStateUpdateCallback<{ companyIds: string[], newVal: string[] }>();

  const companySelectionChange: OnChangeListener<string[]> = {
    performAction(val: string[]) {
      const newVal = form.getFieldValue("partner") || [];
      partnerSelectionUpdate.invokeCallBack({ companyIds: val, newVal });
    }
  };

  useEffect(() => {
    if (rerenderDependentFields) {
      const companyIds = form.getFieldValue("company");
      const newVal = form.getFieldValue("partner");
      partnerSelectionUpdate.invokeCallBack({ companyIds, newVal });
    }
  });

  return <Card size={"small"} style={{ backgroundColor: "#f1f3f5" }}>
    <div className={styles.selfBilledSimpleSearchContainer}>
      <div className={styles.singleColumnGrid}>
        <CompanySelection
          selectionMode={DropDownSelectionMode.MULTIPLE}
          currentUser={currentUser}
          initialValue={initialValuesCache?.getValue("company")}
          intl={intl}
          validator={validator}
          distinctEntitlements={distinctEntitlements}
          filterOptions={descriptionFilter}
          initialization={initialization} form={form}
          onSelectionChange={companySelectionChange}  companyEntitlement={reportEntitlement}/>
        <PartnerSelection
          selectionMode={DropDownSelectionMode.MULTIPLE}
          currentUser={currentUser} intl={intl}
          partnerEntitlement={reportEntitlement}
          validator={validator}
          initialValue={initialValuesCache?.getValue("partner")}
          distinctEntitlements={distinctEntitlements}
          initialization={initialization} form={form}
          filterOptions={descriptionFilter}
          companyIds={initialValuesCache?.getValue("company")}
          externalStateUpdater={partnerSelectionUpdate} />
        <InvoiceStatusSelection
          currentUser={currentUser}
          intl={intl}
          validator={validator}
          initialValue={initialValuesCache?.getValue("invoiceStatus")}
          distinctEntitlements={distinctEntitlements}
          initialization={initialization}
          customLabel={intl.formatMessage({id:'select-invoice-status-orders'})}
          customDescription={(item)=>`${item.id} - ${item.description}`}
          sortByDescription={true}
          form={form} reportName={"sr1"} />
        <DeliveredStatusSelection
          currentUser={currentUser}
          intl={intl}
          initialValue={initialValuesCache?.getValue("deliveredStatus")}
          distinctEntitlements={distinctEntitlements}
          initialization={initialization}
          form={form} />
        <PurchaseOrderFromToSelection
          validator={validator}
          dateFormatter={getDateFormatOrDefault(currentUser)}
          initialValue={initialValuesCache?.getValue("purchaseOrderDate", stringToDayjs_yyyy_MM_dd)}
          currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
          initialization={initialization} form={form} />
      </div>

      <div className={styles.singleColumnGrid}>
        <BusinessAreaListSelection
          selectionMode={DropDownSelectionMode.MULTIPLE}
          initialValue={initialValuesCache?.getValue("businessAreaCode")}
          filterOptions={descriptionFilter}
          currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
          initialization={initialization} form={form} />
        <PurchaseOrderNumber
          currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
          initialInputValue={initialValuesCache?.getValue("purchaseOrderNumber")}
          initialSelectedOperator={initialValuesCache?.getValueOrDefault("purchaseOrderNumberOperator", "CP")}
          initialization={initialization} form={form} />
        <SettlementTypeSelection
          currentUser={currentUser}
          intl={intl}
          initialValue={initialValuesCache?.getValue("settlementType")}
          distinctEntitlements={distinctEntitlements}
          initialization={initialization}
          filterOptions={descriptionFilter}
          form={form} />
        <PlantInfoSelection
          selectionMode={DropDownSelectionMode.MULTIPLE}
          initialValue={initialValuesCache?.getValue("plantInfo")}
          filterOptions={descriptionFilter}
          currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
          initialization={initialization} form={form} reportName={"sr1"} />
        <InvoicingPartySelection
          initialValue={initialValuesCache?.getValue("invoicingParty")}
          currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
          initialization={initialization} form={form}
          selectionMode={DropDownSelectionMode.MULTIPLE}
          filterOptions={descriptionFilter}
          companyIds={initialValuesCache?.getValue("company")}
          externalStateUpdater={partnerSelectionUpdate}
          partnerEntitlement={reportEntitlement}/>
      </div>

    </div>
  </Card>;
}

export class PurchaseAgGridColumnDef implements InfoPortalAgGridColumnDef {
  getLineItemColumnDefinitions(intl: IntlShape,user:PortalUser): InfoPortalColumnDef[] {
    return [
      {
        field: "poItem",
        headerName: intl.formatMessage({ "id": "grid-heading-purchaseOrderItem", defaultMessage: "Purchase Order Item" })
      },
      {
        field: "material",
        headerName: intl.formatMessage({ "id": "grid-heading-material", defaultMessage: "Material Number" })
      },
      {
        field: "materialText",
        headerName: intl.formatMessage({ "id": "grid-heading-materialText", defaultMessage: "Description" })
      },
      {
        field: "quantity",
        headerName: intl.formatMessage({ "id": "grid-heading-quantity", defaultMessage: "Quantity" }),
        valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.quantity, 3),
        filter: "agTextColumnFilter",
        filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
        comparator:numericComparatorUsingUserPreferences(user)
      },
      {
        field: "pounit",
        headerName: intl.formatMessage({ "id": "grid-heading-pounit", defaultMessage: "Unit of Measure" })
      },
      {
        field: "nettitemvalue",
        type: "rightAligned",
        headerName: intl.formatMessage({ "id": "grid-heading-nettitemvalue", defaultMessage: "Item Value" }),
        valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.nettitemvalue),
        filter: "agTextColumnFilter",
        filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
        comparator:numericComparatorUsingUserPreferences(user)
      },
      {
        field: "deliveryNoteDate",
        headerName: intl.formatMessage({ "id": "grid-heading-deliveryNoteDate", defaultMessage: "Delivery Date" }),
        valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.deliveryNoteDate,getDateFormatOrDefault(user)),
        filterParams:filterParamWithUserDateFormatComparator(user),
        filter:'agDateColumnFilter',
        comparator: dateComparatorUsingUserPreferences(user)
      },
      {
        field: "plantName",
        headerName: intl.formatMessage({ "id": "grid-heading-plantName", defaultMessage: "Plant" })
      },
      {
        field: "busArea",
        headerName: intl.formatMessage({ "id": "grid-heading-busArea", defaultMessage: "Business Area" })
      },
      {
        field: "changedate",
        headerName: intl.formatMessage({ "id": "grid-heading-changedate", defaultMessage: "Change Date" }),
        valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.changedate,getDateFormatOrDefault(user)),
        filterParams:filterParamWithUserDateFormatComparator(user),
        filter:'agDateColumnFilter',
        comparator: dateComparatorUsingUserPreferences(user)
      },
      {
        field: "contactGrName",
        headerName: intl.formatMessage({
          "id": "grid-heading-contactGrName",
          defaultMessage: "Recipient of goods/services (name)"
        })
      },
      {
        field: "contactGrEmail",
        headerName: intl.formatMessage({
          "id": "grid-heading-contactGrEmail",
          defaultMessage: "Recipient of goods/services (email)"
        })
      },
      {
        field: "deliveryaddress",
        headerName: intl.formatMessage({ "id": "grid-heading-deliveryaddress", defaultMessage: "Delivery Address" })
      }
    ];
  }

  getDefaultColumnDefinitions(intl: IntlShape,user:PortalUser): InfoPortalColumnDef[] {
    return [
      { field: "compname", headerName: intl.formatMessage({ "id": "grid-heading-company" }) },
      { field: "partnerlf", headerName: intl.formatMessage({ "id": "grid-heading-partnerNumber" }) },
      { field: "settletypetxt", headerName: intl.formatMessage({ "id": "grid-heading-settlementType" }) },
      { field: "potype", headerName: intl.formatMessage({ "id": "grid-heading-purchaseOrderType" }) },
      { field: "ponumber", headerName: intl.formatMessage({ "id": "grid-heading-purchaseOrderNumber" }) },
      {
        field: "podate",
        headerName: intl.formatMessage({ "id": "grid-heading-purchaseOrderDate" }),
        valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.podate,getDateFormatOrDefault(user)),
        filterParams:filterParamWithUserDateFormatComparator(user),
        filter:'agDateColumnFilter',
        comparator: dateComparatorUsingUserPreferences(user)
      },
      {
        field: "povalue",
        headerName: intl.formatMessage({ "id": "grid-heading-purchaseOrderValue" }),
        type:"rightAligned",
        valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.povalue),
        filter: "agTextColumnFilter",
        filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
        comparator:numericComparatorUsingUserPreferences(user)
      },
      { field: "currency", headerName: intl.formatMessage({ "id": "grid-heading-currency" }) },
      { field: "podlvstattxt", headerName: intl.formatMessage({ "id": "grid-heading-deliveryStatus" }) },
      { field: "invstattxt", headerName: intl.formatMessage({ "id": "grid-heading-invoiceStatusPurchaseOrder" }) },
      { field: "plant", headerName: intl.formatMessage({ "id": "grid-heading-plant" }) },
      { field: "busarea", headerName: intl.formatMessage({ "id": "grid-heading-businessArea" }) },
      { field: "pmnttrms", headerName: intl.formatMessage({ "id": "grid-heading-paymentTerms" }) },
      { field: "termsdelivery", headerName: intl.formatMessage({ "id": "grid-heading-termsofDelivery" }) },
      { field: "contactponame", headerName: intl.formatMessage({ "id": "grid-heading-purchasingname" }) },
      { field: "contactpoemail", headerName: intl.formatMessage({ "id": "grid-heading-purchasingemail" }) },
      { field: "invoicee", headerName: intl.formatMessage({ "id": "grid-heading-invoicingParty" }) }
    ];
  }

  getShortViewColumns(): string[] {
    return [
      "compname",
      "partnerlf",
      "settletypetxt",
      "potype",
      "ponumber",
      "podate",
      "povalue",
      "currency",
      "podlvstattxt",
      "invstattxt",
      "plant",
      "busarea"
    ];
  }
}

export class SR1Report implements InfoPortalReport {
  private _config = new PurchaseAgGridColumnDef();

  getColumnsDefinition(): InfoPortalAgGridColumnDef {
    return this._config;
  }
  
  performSearch(form: FormInstance, currentUser: any, distinctEntitlements: string[]): Promise<InfoPortalGridService> {
    return retrieveSR1Report(form).then(result =>
        new InfoPortalGridService(
            new ReportViewConfig("SR1", this._config), result, distinctEntitlements));
  }

  hasExpertSearch(): boolean {
    return false;
  }

  renderExpertSearch({
                       currentUser,
                       intl,
                       distinctEntitlements,
                       initialization,
                       form,
                       initialValuesCache
                     }: InfoPortalSearchProperties): JSX.Element {
    return undefined;
  }

  renderSimpleSearch({
                       currentUser,
                       intl,
                       distinctEntitlements,
                       initialization,
                       form,
                       initialValuesCache,
                       rerenderDependentFields
                     }: InfoPortalSearchProperties): JSX.Element {
    return <PurchaseOrderSimpleSearch initialization={initialization}
                                      currentUser={currentUser}
                                      intl={intl}
                                      form={form}
                                      distinctEntitlements={distinctEntitlements}
                                      rerenderDependentFields={rerenderDependentFields}
                                      initialValuesCache={initialValuesCache}
                                      reportEntitlement={this.getEntitlement()}/>;
  }


  getEntitlement(): string {
    return "display_sr@reports";
  }

  getReportName(): string {
    return "SR1";
  }
}