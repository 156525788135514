import * as React from 'react';
import { Button, Popover, Tag } from 'antd';
import moment from 'moment';

export function renderInvoiceStatusUpdateDateTime(updateDateTime) {
    if (updateDateTime) {
        return <span>{moment(updateDateTime).format('YYYY-MM-DD')}</span>;
    }
    return '';
}


export function renderInvoiceStatus(statusValue) {
    let statusColor: string;

    switch (statusValue) {
        case 'PASS':
            statusColor = 'green';
            break;
        case 'FAIL':
            statusColor = 'red';
            break;
        case 'WARN':
            statusColor = 'volcano';
            break;
        case 'OVERDUE':
            statusColor = '#FF4500';
            break;
        case 'BUSN':
            statusColor = '#e99813';
            break;
    }
    return (
        <Tag color={statusColor} style={{ width: 80 }}>
            {statusValue}
        </Tag>
    );
}

export function renderInvoiceSummaryStatus(statusValue) {
    let statusColor: string;

    switch (statusValue) {
        case 'COMPLETED':
            statusColor = 'green';
            break;
        case 'FAILED':
            statusColor = '#e9190b';
            break;
        case 'OVERDUE':
            statusColor = '#FF4500';
            break;
        case 'WARN':
            statusColor = 'volcano';
            break;
        case 'BUSN':
            statusColor = '#e99813';
            break;
        case 'INTRANSIT':
            statusColor = '#e98813';
            break;
    }
    return (
        <Tag color={statusColor} style={{ width: 100 }}>
            {statusValue}
        </Tag>
    );
}

export function renderInvoiceStatusReason(statusReason) {
    if (statusReason !== null && statusReason !== undefined && statusReason.trim().length > 0) {
        return (
            <Popover
                getPopupContainer={() => document.body.getElementsByTagName('main')[0] as HTMLElement}
                content={statusReason}
                title="Reason"
            >
                <Button type={'primary'} size={'small'}>
                    {statusReason.slice(0, 49)}
                </Button>
            </Popover>
        );
    }
    return '';
}
