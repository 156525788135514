import 'ag-grid-enterprise/chartsModule';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import React, { useState, useEffect, useRef, FunctionComponent } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Card, Col, notification, Row, Spin, Tabs } from 'antd';
import moment, { utc } from 'moment';
import {
    QUERY_INVOICE_FILTERS,
    QUERY_INVOICE_STATUS,
} from './invotrackQueries';
import { EIMMonitoringGridRequest } from './interfaces';
import InvoTrackServerSideDatasource from './InvoTrackServerSideDatasource';
import { invoiceListColumns, invoiceListDefaultColDef } from './utils';
import InvoiceStatusSearchForm from './InvoiceStatusSearchForm';
import { exportMonitoringData } from './excelUtil';
import { cloneDeep } from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import { IAppComponentProps } from '../../components';
import client from '../../config/GraphQLApolloClient';
import { useIntl } from 'react-intl';

const InvoiceStatusView: FunctionComponent<IAppComponentProps> = ( props ) => {
    const [loading, setLoading] = useState(false);
    const intl = useIntl();
    const [searchFilters, setSearchFilters] = useState<EIMMonitoringGridRequest>({
        limit: 1000,
        offset: 0,
        page: 0,
    });
    const [gridOptions, setGridOptions] = useState({
        defaultColDef: invoiceListDefaultColDef,
        columnDefs: invoiceListColumns,
        cacheBlockSize: 2000,
        maxBlocksInCache: 10000,
        enableServerSideSorting: true,
        enableServerSideFilter: true,
    });

    const gridApi = useRef(null);
    const gridColumnApi = useRef(null);



    const getFilters = async () => {
        try {
            const response = await client.query({ query: QUERY_INVOICE_FILTERS, fetchPolicy: 'no-cache' });
            const updatedColumns = invoiceListColumns.map((column) => {
                const filterValues = response.data?.getAllMessagesFilters?.[column.field];
                return filterValues ? { ...column, filterParams: { values: filterValues.map(f => f.value) } } : column;
            });
            //setGridOptions( {...gridOptions, columnDefs: updatedColumns} );
            gridApi.current.setColumnDefs(updatedColumns);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getFilters();
    }, []);

    const reload = () => {
        window.localStorage.clear();
        location.reload();
    };

    const onGridReady = (params) => {
        gridApi.current = params.api;
        gridColumnApi.current = params.columnApi;
        params.api.sizeColumnsToFit();
        gridApi.current.setServerSideDatasource(new InvoTrackServerSideDatasource(gridOptions, client, searchFilters));
    };

    const onFilterChanged = (params) => {
        ['process', 'duplicate', 'm_interface', 'status', 'step_number', 'step', 'company', 'sys_id'].forEach((item) => {
            params.api.getFilterInstance(item)?.refreshFilterValues();
        });
    };

    const handleSearch = (values) => {
        setSearchFilters(values);
        gridApi.current.setServerSideDatasource(new InvoTrackServerSideDatasource(gridOptions, client, values));
    };

    const handleReset = () => {
        setSearchFilters({ limit: 100, offset: 0, page: 0 });
    };

    const handleDownload = async (values) => {
        setLoading(true);
        try {
            const response = await client.query({ query: QUERY_INVOICE_STATUS, variables: { invoTrackGridRequest: values }, fetchPolicy: 'no-cache' });
            const invoiceStatusesList = cloneDeep(response.data.getEIMInvoices.invoiceStatusesList);
            exportMonitoringData(invoiceStatusesList, `${moment().format('YYYY-MM-DD HH:mm')}-eInvoicingMonitoringExport.xlsx`);
        } catch (error) {
            notification.error({ message: 'Download Error', description: error.message });
            console.error(error);
        }
        setLoading(false);
    };

    return (
        <Spin spinning={loading} size={'large'} indicator={<LoadingOutlined style={{ fontSize: 50 }} />}>
            <div className={`ag-theme-balham `}>
                <Tabs size={'small'} type={'card'}>
                    <Tabs.TabPane tab={intl.formatMessage({ id: 'titlePartnerView' })} key="search">
                        <Row>
                            <Col xl={24}>
                                <Card size="default">
                                    <InvoiceStatusSearchForm
                                        searchModel={searchFilters}
                                        onSearch={handleSearch}
                                        onReset={handleReset}
                                        onDownload={handleDownload}
                                        onReload={reload}
                                        onCount={reload}
                                        intl={intl}

                                    />
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24}>
                                <div className="ag-theme-balham" style={{ flex: 'auto' , height: '800px' }}>
                                    <AgGridReact
                                        key={'invoiceStatusListTable'}
                                        rowModelType={'serverSide'}
                                        columnDefs={gridOptions.columnDefs}
                                        rowSelection={'multiple'}
                                        paginationAutoPageSize={true}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={onGridReady}
                                        defaultColDef={gridOptions.defaultColDef}
                                        animateRows={true}
                                        gridOptions={gridOptions}
                                        //showQuickFilter={false}
                                        onFilterChanged={onFilterChanged}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </Spin>
    );
};

export default InvoiceStatusView;
