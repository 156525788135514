import React from 'react';
import {
    renderInvoiceStatus,
    renderInvoiceStatusReason,
    renderInvoiceStatusUpdateDateTime, renderInvoiceSummaryStatus,
} from './decorators/decorator';


export const invoiceListDefaultColDef = {
    editable: true,
    /* enableRowGroup: true,
    enablePivot: true,
    enableValue: true,*/
    sortable: true,
    resizable: true,
};

export const invoiceListColumns = [
    {
        field: 'message_id',
        headerName: 'UUID',
        width: 250,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'partnerno',
        headerName: 'PARTNERNO',
        width: 120,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'sys_id',
        headerName: 'SYS_ID',
        width: 150,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: [],
            debounceMs: 2000,
            refreshValuesOnOpen: true,
        },
    },
    {
        field: 'company',
        headerName: 'COD',
        width: 80,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: [],
            debounceMs: 2000,
            refreshValuesOnOpen: true,
        },
    },

    {
        field: 'reference',
        headerName: 'REFERENCE',
        width: 150,
        filter: false,
    },

    {
        field: 'duplicate',
        headerName: 'DUPE',
        width: 80,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: [],
            debounceMs: 2000,
            refreshValuesOnOpen: true,
        },
    },

    {
        field: 'cross_border',
        headerName: 'CB',
        width: 70,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['Y', 'N'],
            debounceMs: 2000,
        },
        hide: true,
    },
    {
        field: 'process',
        headerName: 'PROCESS',
        width: 120,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: [],
            debounceMs: 2000,
        },
    },
    {
        field: 'm_interface',
        headerName: 'INTERFACE',
        width: 200,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: [],
            debounceMs: 2000,
            refreshValuesOnOpen: true,
        },
    },

    {
        field: 'step_number',
        headerName: 'STEP NO',
        width: 80,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: [],
            debounceMs: 2000,
            refreshValuesOnOpen: true,
        },
    },
    {
        field: 'step',
        headerName: 'STEP',
        width: 100,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: [],
            debounceMs: 2000,
            refreshValuesOnOpen: true,
        },
    },
    {
        field: 'status',
        headerName: 'STATUS',
        width: 80,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: [],
            debounceMs: 2000,
            refreshValuesOnOpen: true,
        },
        cellRendererFramework: (props) => renderInvoiceStatus(props.value),
    },

    {
        field: 'status_reason',
        headerName: 'REASON',
        width: 200,
        cellRendererFramework: (props) => renderInvoiceStatusReason(props.value),
        filter: false,
    },

    {
        field: 'creation_ts',
        headerName: 'UPDATETIME',
        width: 130,
        filter: false,
    },
    {
        field: 'sdi_number',
        headerName: 'SDI No.',
        width: 150,
        filter: false,
    },
    {
        field: 'invoice_date',
        headerName: 'INV DATE',
        width: 150,
        cellRendererFramework: (props) => renderInvoiceStatusUpdateDateTime(props.value),
        filter: false,
    },
    {
        field: 'log_status_id',
        headerName: 'T_LOG_STATUS-LOG_STATUS_ID',
        width: 150,
        filter: false,
        hide: true,
    },
    {
        field: 'log_obj_id',
        headerName: 'T_LOG_STATUS-LOG_OBJ_ID',
        width: 150,
        filter: false,
        hide: true,
    },

    {
        field: 'edi_agreement_date',
        headerName: 'EDI_AGMT_DATE',
        width: 150,
        filter: false,
        hide: true,
    },
    {
        field: 'ebox_folder',
        headerName: 'EBOX FOLDER',
        width: 150,
        filter: true,
        hide: true,
    },
];

export const summaryInvoiceListColumns = [
    {
        field: 'partnerno',
        headerName: 'PARTNERNO',
        width: 120,
        filter: false,
        cellRenderer: 'agGroupCellRenderer',
    },
    {
        field: 'company',
        headerName: 'COD',
        width: 80,
        filter: false,
        filterParams: {
            values: [],
            debounceMs: 2000,
        },
    },

    {
        field: 'reference',
        headerName: 'REFERENCE',
        width: 150,
        filter: false,
    },
    {
        field: 'm_process',
        headerName: 'PROCESS',
        width: 120,
        filter: false,
        filterParams: {
            values: [],
            debounceMs: 2000,
        },
    },
    {
        field: 'inv_status',
        headerName: 'STATUS',
        width: 80,
        filter: false,
        filterParams: {
            values: [],
            debounceMs: 2000,
        },
        cellRendererFramework: (props) => renderInvoiceSummaryStatus(props.value),
    },
    {
        field: 'insert_ts',
        headerName: 'RECEIVED_DATE',
        width: 130,
        filter: false,
    },
    {
        field: 'complete_date',
        headerName: 'COMPLETE DATE',
        width: 130,
        filter: false,
    },
];

export const detailCellRenderer = {
    detailGridOptions: {
        columnDefs: [
            { field: 'duplicate', cellRenderer: 'agGroupCellRenderer', filter: false },
            { field: 'received_date', filter: false },
            { field: 'complete_date', filter: false },
            {
                field: 'status',
                cellRendererFramework: (props) => renderInvoiceSummaryStatus(props.value),
                filter: false,
            },
            { field: 'message_id', filter: false, width: 300 },
        ],
        resizable: true,
        masterDetail: true,
        detailRowAutoHeight: true,
        detailCellRendererParams: {
            detailGridOptions: {
                columnDefs: [
                    { field: 'step_number', filter: false },
                    { field: 'step', filter: false },
                    {
                        field: 'status',
                        cellRendererFramework: (props) => renderInvoiceStatus(props.value),
                        filter: false,
                    },
                    { field: 'process', filter: false },
                ],
                onFirstDataRendered: (params) => {
                    params.api.sizeColumnsToFit();
                },
            },
            getDetailRowData: (params) => {
                params.successCallback(params.data.children);
            },
        },
    },
    getDetailRowData: (params) => {
        params.successCallback(params.data.children);
    },
};
