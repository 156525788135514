import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, message, Row, Typography } from 'antd';

import { IAppComponentProps } from '../../../components';
import { SurveyContactInput } from '../../../../__generated__/globalTypes';
import { getSurveyContactByType, updateSurveyContact } from './SurveyConnectivityService';
import { useIntl } from 'react-intl';
import Paragraph from 'antd/es/typography/Paragraph';
import { StateContainer } from '../utilities/StateContainer';
import { MODE } from '../IStoredSurvey';
import { useAppDispatch, useAppSelector } from '../../../main/hooks';
import { resetSurveyDataLoading, setSurveyDataLoading } from '../IStoredSurveySlice';
import { convertFromSpecifiedDateFormatToUserDefined } from '../../users/UserFormatPreferenceServices';
import { getI18n } from '../../../utils/Utils';
import { SurveyContactResponse } from '../../../__generated__/types';
import { GetSurveyContactByTypeQuery } from '../queries.generated';
import { getSurveyContactByType_getSurveyContactByType } from '../__generated__/getSurveyContactByType';

type ISurveyConnectivityInfoContactProps = {
    groupId?: any;
    onStatusUpdate?: any;
    stateContainer?: StateContainer;
} & IAppComponentProps;

const SurveyConnectivityContact: FunctionComponent<ISurveyConnectivityInfoContactProps> = (props) => {

    const TECHNICAL_CONTACT_1 = 1;
    const TECHNICAL_CONTACT_2 = 4

    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [form] = Form.useForm();
    // const [loading, setLoading] = useState(false);
    const [contactInfo, setContactInfo] = useState(undefined);
    const [changed, setChanged] = useState(false);
    const { Text } = Typography;

    const surveyState = useAppSelector((state) => state.survey);

    const isViewMode = () => {
        return surveyState.survey.surveyMode === MODE.VIEW ? true : false;
    };

    const isOverViewMode = () => {
        return surveyState.survey.surveyMode === MODE.OVERVIEW ? true : false;
    };

    const formatContactModificationTimestamp = (timeStamp: string) => {
        if (timeStamp) {
            return `${convertFromSpecifiedDateFormatToUserDefined(
                props.currentUser,
                timeStamp.substring(0, 10),
                'YYYY-MM-DD'
            )} ${timeStamp.substring(11)}`;
        }

        return timeStamp;
    };

    const loadSurveyContact = async (groupId, contactType) => {
        const response:any = await getSurveyContactByType(groupId, contactType);
        if (response.getSurveyContactByType?.length > 0) {
            const contactInfos = response.getSurveyContactByType.map((item) => ({
                contactFirstName: item.contactFirstName,
                contactLastName: item.contactLastName,
                contactNumber: item.contactNumber,
                contactEmailAddres: item.contactEmailAddres,
                updateTime: formatContactModificationTimestamp(item.updateTime),
            }));

            return contactInfos;
        }
        return [];
    };

    const loadData = () => {
        dispatch(setSurveyDataLoading(true));

        const contactTypes = [TECHNICAL_CONTACT_1, TECHNICAL_CONTACT_2];

        Promise.all(contactTypes.map(contactType => loadSurveyContact(props.groupId, contactType)))
            .then(results => {
                const contactInfos = results.flat();
                setContactInfo(contactInfos);
                form.setFieldsValue({ items: contactInfos && contactInfos.length > 0 ? contactInfos : [{}] });
            })
            .catch(() => {
                dispatch(resetSurveyDataLoading(false));
            })
            .finally(() => {
                dispatch(setSurveyDataLoading(false));
            });
    };

    const onFinish = async (itemValues: any) => {
        console.info(itemValues);

        dispatch(setSurveyDataLoading(true));
        const contactInfos: SurveyContactInput[] = [];

        let index = 1;
        try {
            for (const values of itemValues.items) {
                const contactInfo: SurveyContactInput = { ...values, contactTypeId: index === 1?TECHNICAL_CONTACT_1:TECHNICAL_CONTACT_2, updateTime: null };
                const response = await updateSurveyContact(props.groupId, contactInfo);
                const updatedContactInfo = {
                    contactFirstName: response.updateSurveyContact.contactFirstName,
                    contactLastName: response.updateSurveyContact.contactLastName,
                    contactNumber: response.updateSurveyContact.contactNumber,
                    contactEmailAddres: response.updateSurveyContact.contactEmailAddres,
                    updateTime: formatContactModificationTimestamp(response.updateSurveyContact.updateTime),
                };
                contactInfos.push(updatedContactInfo);
                index++;
            }

            // Show success message after the loop completes
            message.success(getI18n(
                'survey-connectivity-save-contact-success-message',
                'Connectivity Information saved successfully',
                intl
            ));
        } catch (error) {
            dispatch(resetSurveyDataLoading(false));
            message.error(getI18n(
                'survey-connectivity-save-contact-failed-message',
                'Failed to save connectivity information',
                intl
            ));
        } finally {
            setContactInfo(contactInfos);
            form.setFieldsValue({items:contactInfos});
            props.onStatusUpdate();
            dispatch(setSurveyDataLoading(false));
        }
    };
    useEffect(() => {
        if (contactInfo === undefined) {
            loadData();
        }
    }, [contactInfo]);

    return (
        <>
            <Row>
                <Col>
                    <Text type="secondary">GroupId: {props.groupId}</Text>
                </Col>
            </Row>
            <Row gutter={[24, 10]}>
                <Col>
                    <Typography>
                        <Paragraph>
                            {intl.formatMessage({
                                id: 'survey-connectivityInfo-technicalContactDetails-description',
                                defaultMessage:
                                    'Provide Technical Contact Person Details. This is the person who is contacted to support and complete the eInvoice connection connectivity setup.',
                            })}
                        </Paragraph>
                    </Typography>
                    <Typography>
                        <Paragraph strong={true}>
                            {intl.formatMessage({
                                id: 'survey-connectivityInfo-technicalContactDetails-note',
                                defaultMessage:
                                    'NB: To complete the connectivity setup for your respective eInvoice connection, our eInvoice service provider will contact you.',
                            })}
                        </Paragraph>
                    </Typography>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={16}>
                    <Form
                        noValidate={true}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        labelAlign={'left'}
                        onFinish={onFinish}
                        autoComplete="off"
                        initialValues={{ items:  contactInfo && contactInfo.length > 0 ? contactInfo : [{}] }}
                        form={form}
                    >
                        <Form.List name="items">
                            {(fields, { add }) => (
                                <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                                    {fields.map((field) => (
                                        <Card
                                            size="small"
                                            title={intl.formatMessage(
                                                {
                                                    id: 'survey-connectivityInfo-technicalContactDetails-card-title',
                                                    defaultMessage: 'Technical Contact {contactNumber}',
                                                },
                                                { contactNumber: Number(field.name) + 1 }
                                            )}
                                            key={field.key}
                                        >
                                            <Form.Item
                                                label={intl.formatMessage({
                                                    id: 'survey-connectivityInfo-technicalContactDetails-contactName',
                                                    defaultMessage: 'Technical Contact Name',
                                                })}
                                                name={[field.name,'contactFirstName']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: intl.formatMessage({
                                                            id: 'generic-requiredField-message',
                                                            defaultMessage: 'Please Enter Required Data',
                                                        }),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className="mr10"
                                                    type={'text'}
                                                    readOnly={isViewMode() || isOverViewMode()}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label={intl.formatMessage({
                                                    id: 'survey-connectivityInfo-technicalContactDetails-contactSurname',
                                                    defaultMessage: 'Technical Contact Surname',
                                                })}
                                                name={[field.name,'contactLastName']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: intl.formatMessage({
                                                            id: 'generic-requiredField-message',
                                                            defaultMessage: 'Please Enter Required Data',
                                                        }),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className="mr10"
                                                    type={'text'}
                                                    readOnly={isViewMode() || isOverViewMode()}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label={intl.formatMessage({
                                                    id: 'survey-connectivityInfo-technicalContactDetails-contactNumber',
                                                    defaultMessage: 'Technical Contact Number',
                                                })}
                                                name={[field.name,'contactNumber']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: intl.formatMessage({
                                                            id: 'generic-requiredField-message',
                                                            defaultMessage: 'Please Enter Required Data',
                                                        }),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className="mr10"
                                                    type={'tel'}
                                                    readOnly={isViewMode() || isOverViewMode()}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label={intl.formatMessage({
                                                    id: 'survey-connectivityInfo-technicalContactDetails-contactEmail',
                                                    defaultMessage: 'Technical Contact Email Address',
                                                })}
                                                name={[field.name,'contactEmailAddres']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: intl.formatMessage({
                                                            id: 'generic-requiredField-message',
                                                            defaultMessage: 'Please Enter Required Data',
                                                        }),
                                                    },
                                                    {
                                                        type: 'email',
                                                        message: intl.formatMessage({
                                                            id: 'survey-connectivityInfo-invalid-email',
                                                            defaultMessage:
                                                                "Email must include one '@' symbol, a domain, no spaces, and only valid characters.",
                                                        }),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className="mr10"
                                                    type={'email'}
                                                    readOnly={isViewMode() || isOverViewMode()}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label={intl.formatMessage({
                                                    id: 'survey-connectivityInfo-technicalContactDetails-date',
                                                    defaultMessage: 'Date',
                                                })}
                                                name={[field.name,'updateTime']}
                                            >
                                                <Input className="mr10" type={'text'} readOnly={true} />
                                            </Form.Item>
                                        </Card>
                                    ))}
                                    <Form.Item
                                        wrapperCol={{ offset: 8, span: 16 }}
                                        hidden={isViewMode() || isOverViewMode() || fields.length === 2}
                                    >
                                        <Row gutter={12}>
                                            <Col span={12} offset={6}>
                                                <Button type="default" onClick={() => add()} size="middle" className="w-100 mt10">
                                                    {intl.formatMessage({
                                                        id: 'survey-connectivityInfo-technicalContact-add-second',
                                                        defaultMessage: 'Add Second Technical Contact',
                                                    })}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </div>
                            )}
                        </Form.List>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }} hidden={isViewMode() || isOverViewMode()}>
                            <Row gutter={12}>
                                <Col span={12} offset={6}>
                                    <Button type="primary" size="middle" className="w-100 mt10" htmlType="submit">
                                        {intl.formatMessage({
                                            id: 'survey-connectivityInfo-technicalContact-save',
                                            defaultMessage: 'Save Contact details',
                                        })}
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default SurveyConnectivityContact;
