import React, {useEffect, useRef, useState} from "react";
import {
    Alert,
    Badge,
    Button,
    Card,
    Col,
    ConfigProvider,
    Dropdown,
    Form,
    Menu, Modal, notification,
    Row,
    Space,
    Spin,
    Tabs,
    Tooltip
} from "antd";
import {PageContainer} from "@ant-design/pro-layout";
import {FailureDownloadDocsResp, IAppComponentProps} from "../../components";
import styles from "../../styles/scss/portal.scss";
import {AgGridReact} from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {CustomRender} from "../../components/CustomRender";
import {GridApi} from "ag-grid-community";
import client from "../../config/GraphQLApolloClient";
import enUS from "antd/lib/locale/en_US";
import {DOWNLOAD_MULTIPLE_DOCUMENT} from "./queries";
import {showErrorNotification} from "../Notification";
import {UPDATE_MULTIPLE_DOWNLOAD_STATUS} from "./mutations";
import MyDocumentSearchForm from "./myDocumentSearchForm";
import {getDocumentsData} from "./DocumentService";
import {base64toZipFile, MYDOCS_TABLE_HEADERS} from "./DocumentUtils";
import deDe from "antd/lib/locale/de_DE";
import {FormattedMessage, useIntl} from "react-intl";
import {getAgGridLocalization, PersonalizeGridView} from "../info-portal/components/grid/GridUtil";
import {getI18n} from '../../utils/Utils';
import dayjs from 'dayjs';
import {
    DownloadOutlined,
    DownOutlined,
    EyeOutlined,
    ReloadOutlined,
    SaveOutlined,
    UserOutlined
} from "@ant-design/icons";
import moduleStyles from "./styling/document.module.css";
import {MYLISTDOCS_TABLE_HEADERS} from "./ListDocumentsUtils";
import RenderIfEntitled from "../../components/EntitlementComponents";

export const renderAsDate = (record) => <CustomRender record={record} renderAs="date"/>;
export const renderAsButton = (record) => <CustomRender record={record} renderAs="button"/>;

const MyDocuments: React.FunctionComponent<IAppComponentProps> = (props) => {
    const intl = useIntl();
    const [loading, isLoading] = useState(false);
    const currentUser = props.currentUser;
    const [form] = Form.useForm();
    const [docGridApi, setDocGridApi] = useState<GridApi>(undefined);
    const [docData, setDocData] = useState(undefined);
    const [docCount, setDocCount] = useState(0);
    const [_formDataValues, setFormDataValues] = useState(undefined);
    const [failureDocuments, setFailureDocuments] = useState<FailureDownloadDocsResp[]>(undefined);
    const [_flag,setFlag] = useState<boolean>(false);

    const {TabPane} = Tabs;
    const sizes: React.CSSProperties = {
        height: "100%",
        width: "98%"
    };

    useEffect(() => {
        isLoading(false);
        setDocCount(0);
        setDocData(null);


    }, [props.currentUser]);

    const reload = ()=>setFlag(prevState=>!prevState);

    const gridView = useRef<PersonalizeGridView>(
        new PersonalizeGridView(
            'freight-document_ag_grid_personalized_view',
            MYDOCS_TABLE_HEADERS(props.intl, props.currentUser)));

    const onDocGridReady = (params) => {
        setDocGridApi(params.api);
        gridView.current.initPersonalizedView(params.api);

    };

    const onSearch = (values) => {
        isLoading(true);
        setFormDataValues(values);

        const documentInput = {
                fromDate: values.fromDate ? dayjs(values.fromDate.startOf("d")) : undefined,
                toDate: values.dateTo ? dayjs(values.dateTo.endOf("d")) : undefined,
                companyCode: values.companyCode,
                reference: values.reference ? values.reference.trim() : undefined,
                partnerno: values.partnerNo ? values.partnerNo.trim() : undefined,
                downloaded: values.downloaded,
                orderArea: values.orderArea
            }
        ;
        console.log('The values', values);
        console.log('The document input', documentInput);
        localStorage.setItem("documentInput", JSON.stringify(documentInput));
        Promise.resolve(getDocumentsData(currentUser.username, documentInput))
            .then((data) => {
                // eslint-disable-next-line no-console
                setDocCount(data.getPartnerDocuments.length);
                setDocData(data.getPartnerDocuments);
                isLoading(false);
            })
            .catch((err) => {
                isLoading(false);
                console.error(err);
            });

    };

    const onExport = () => {
        const columnIDS = docGridApi.getColumnDefs().filter(item=>!item['hide']).map(item => item['colId']);
        const selectedRows = docGridApi.getSelectedRows();

        if (selectedRows?.length && columnIDS.length) {
            docGridApi.exportDataAsExcel({
                onlySelectedAllPages: true,
                columnKeys: columnIDS.slice(0, columnIDS.length)
            });
        }
        else {
            Modal.warning({
                title: getI18n(
                    'Warning-message',
                    'Export Document(s)', intl,
                ),
                content: getI18n(
                    'Warning-export-message',
                    'You have not selected any items to be exported. Please select an item first.', intl,
                ),
            });
        }
    };

    const downloadDocuments = (values) => {
        isLoading(true);
        setFormDataValues(values);
        const documentDownloadInputList = [];
        const selectedRows = docGridApi.getSelectedRows();

        if (selectedRows?.length) {
            for (const document of selectedRows) {
                documentDownloadInputList.push({
                    blobId: document.documentId,
                    documentName: document.azDownloadFileName,
                    documentPath: document.azItemPathName,
                    documentLink: document.downloadLink
                });
                console.log(documentDownloadInputList);
            }
            client
                .query({
                    query: DOWNLOAD_MULTIPLE_DOCUMENT,
                    variables: {
                        documentDownloadInputs: documentDownloadInputList
                    },
                    fetchPolicy: "network-only"
                })
                .then((response) => {
                    base64toZipFile(response.data.downloadMultipleDocument.encodedZipFileData, new Date().toISOString().substring(0, 10).concat("-download.zip"));
                    setFailureDocuments(response.data.downloadMultipleDocument.failureDocuments);
                    isLoading(false);
                })
                .then(() => {
                    client.mutate({
                        mutation: UPDATE_MULTIPLE_DOWNLOAD_STATUS,
                        variables: {documentDownloadInputs: documentDownloadInputList}
                    }).then((_) => {
                        onSearch(values);
                    }).catch((e) => {
                        console.error(e);
                    });
                })
                .catch((e) => {
                    console.error(e);
                    isLoading(false);
                    showErrorNotification(`${e}`);
                });
        }
        else {
            Modal.warning({
                title: getI18n(
                    'Warning-message',
                    'Export Document(s)', intl,
                ),
                content: getI18n(
                    'Warning-export-message',
                    'You have not selected any items to be exported. Please select an item first.', intl,
                ),
            });
        }
    };

    const onSave = (_: any) => {
        gridView.current.savePersonalizedView();
        reload();
        notification.info({ message: intl.formatMessage({ id: 'report-result-preferences-saved' }) });
    };

    const onApplyPerson = (_: any) => {
        gridView.current.applyPersonalizedView();
    };

    const onReset = (_: any) => {
        docGridApi.setFilterModel(null);
        gridView.current.reset();
    };

    const downIcon = () => <DownOutlined />;

    const menu = (
        <Menu>
            <Menu.Item key="3" disabled={!gridView.current.hasPersonalizedView()}>
                <UserOutlined onClick={onApplyPerson} />
                <a data-testid={'info-portal-personal'} onClick={onApplyPerson}>
                    {intl.formatMessage({ id: 'info-portal-personal', defaultMessage: 'Personal' })}
                </a>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="4">
                <SaveOutlined onClick={onSave} />

                <a data-testid={'info-portal-save'} onClick={onSave}>
                    {intl.formatMessage({ id: 'info-portal-save', defaultMessage: 'Save' })}
                </a>
            </Menu.Item>
        </Menu>
    );


    return (
        <ConfigProvider locale={localStorage.getItem("locale") === "us" ? enUS : deDe}>
            <PageContainer
                title={false}
                content={""}
                extraContent={""}
                className={styles.users}
                style={{background: "white"}}
            >
                <Spin spinning={loading} size={"large"}>

                    <div style={{...sizes, minHeight: "200px"}} className="pl15 ag-theme-balham">
                        <Col md={24} sm={12} xs={24}>
                                <Card title={getI18n("freight_panel_header_title", "Document view", intl)}
                                         key="1">
                                    <MyDocumentSearchForm
                                                          onSearch={onSearch} currentUser={currentUser}
                                                          intl={props.intl}
                                                          distinctEntitlements={props.distinctEntitlements}/>

                                    {failureDocuments &&
                                        <Row gutter={24}>
                                            <Col span={24} className="mb5 mt5">
                                                {failureDocuments.map((x, i) =>
                                                    <Alert key={i} message={`${x.errorMessage}`} type="error"
                                                           showIcon={true} closable={true}/>)}
                                            </Col>
                                        </Row>}

                                    <Tabs defaultActiveKey={'1'} className="users-tabs">
                                        <TabPane
                                            tab={getI18n(
                                                'Document-title-overview-documents',
                                                'Documents',
                                                intl,
                                            )}
                                            key="1"
                                        >

                                            <div style={{paddingBottom: '20px', display: 'block'}}>
                                                <div>
                                                    <label>{getI18n('welcome-No_documents', '  Number Of Documents  ', intl)} </label>
                                                    <Space>
                                                        <Badge
                                                            className="site-badge-count-109"
                                                            count={docCount}
                                                            style={{backgroundColor: '#52c41a'}}
                                                            title={'Number of Documents'}
                                                            overflowCount={999999}
                                                        />
                                                    </Space>
                                                </div>

                                                <Row gutter={24} style={{paddingTop: '5px'}}>
                                                    <Col style={{paddingRight: '5px'}}>
                                                        <Dropdown.Button
                                                            data-testid={'view-result-customization'}
                                                            overlay={menu} trigger={['click']}
                                                            icon={downIcon()} size={'middle'}>
                                                            <EyeOutlined/>
                                                            {intl.formatMessage({
                                                                id: 'info-portal-view',
                                                                defaultMessage: 'View',
                                                            })}
                                                        </Dropdown.Button>
                                                    </Col>

                                                    <Col style={{paddingRight: '5px'}}>
                                                        <Tooltip
                                                            title={intl.formatMessage({id: 'reset-filter'})}>
                                                            <Button
                                                                data-testid={'view-result-customization-reset'}
                                                                onClick={onReset} size={'middle'}
                                                                icon={<ReloadOutlined
                                                                    className={moduleStyles.simpleFlipRefresh}/>}>
                                                                {intl.formatMessage({id: 'info-portal-reset'})}
                                                            </Button>
                                                        </Tooltip>
                                                    </Col>
                                                    <RenderIfEntitled entitlementToCheck="download@freight" intl={intl} distinctEntitlements={props.distinctEntitlements}>
                                                        <Col style={{paddingRight: '5px'}}>
                                                            <Tooltip
                                                                title={intl.formatMessage({id: 'welcome-download-button'})}>
                                                                <Button data-testid={'result-download'}
                                                                        onClick={_=>downloadDocuments(form.getFieldsValue())}
                                                                        icon={<DownloadOutlined/>}
                                                                        size={'middle'}>
                                                                    {intl.formatMessage({id: 'welcome-download-button'})}
                                                                </Button>
                                                            </Tooltip>
                                                        </Col>
                                                    </RenderIfEntitled>
                                                    <Col style={{paddingRight: '5px'}}>
                                                        <Tooltip
                                                            title={intl.formatMessage({id: 'export-to-excel'})}>
                                                            <Button data-testid={'result-export'}
                                                                    onClick={onExport}
                                                                    icon={<DownloadOutlined/>}
                                                                    size={'middle'}>
                                                                {intl.formatMessage({id: 'export'})}
                                                            </Button>
                                                        </Tooltip>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div style={{flex: "auto", height: "700px"}}>
                                                <AgGridReact
                                                    defaultColDef={{
                                                        enableValue: true,
                                                        sortable: true,
                                                        resizable: true,
                                                    }}
                                                    api={docGridApi}
                                                    columnDefs={gridView.current.getView()}
                                                    rowData={docData}
                                                    sideBar={false}
                                                    rowClass="agGridRows"
                                                    suppressMenuHide={true}
                                                    debug={true}
                                                    onGridSizeChanged={() => docGridApi.sizeColumnsToFit()}
                                                    onGridReady={onDocGridReady}
                                                    rowSelection={"multiple"}
                                                    pagination={true}
                                                    localeText={getAgGridLocalization(intl)}
                                                    paginationPageSize={20}
                                                    suppressRowClickSelection={true}
                                                    enableBrowserTooltips={true}
                                                    data-testid="documents-test-id"/>
                                            </div>
                                        </TabPane>
                                    </Tabs>

                                </Card>
                        </Col>
                    </div>
                </Spin>
            </PageContainer>
        </ConfigProvider>
    )
        ;
};
export default MyDocuments;
