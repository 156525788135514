import {Button, Col, Form, Input, List, message, Modal, notification, Row, Select, Spin, Tabs} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import {IAppComponentProps} from "../../components";
import {AgGridReact} from "ag-grid-react";
import {COMPANY_TABLE_HEADERS, COMPANY_TABLE_HEADERS_WITH_DETAILS, COMPANY_TABLE_HEADERS_WITH_PARTNER_TYPE} from "../../pages/users/UserUtils";
import {GridApi} from "ag-grid-community";
import {getAgGridLocalization} from "../../pages/info-portal/components/grid/GridUtil";
import {DropDownSelection} from "../../pages/info-portal/InfoPortalInterfaces";
import {
    retrieveDateFormatOptions,
    retrieveLanguageOptions,
    retrieveNumericFormats,
    saveUserFormatPreferences
} from "../../pages/users/UserFormatPreferenceServices";
import {SetUserPreferences} from "../../../__generated__/globalTypes";
import {triggerLanguageChange} from "../../store/IEDPGlobalSlice";
import {useAppDispatch, useAppSelector} from "../../main/hooks";
import { useGetPartnerLinksQuery } from "../../pages/users/queries.generated";
import { EntitlementCompanyInput, EntitlementPartnerInput } from "src/__generated__/types";
import { isArray } from "lodash";

export interface IProps extends IAppComponentProps {
    onCancel: any;
    visible: any;
    selectedUser: any;
}

const getRowStyle = (params) => {
    if (params.data.details && params.data.details.length > 0) {
        return { backgroundColor: '#dff0d8' }; // Light green background color
    }
    return null;
};

type CancelSubmitButtonVisibility = { cancelVisibility: boolean, submitVisible: boolean };

const NewUserProfileModal: React.FunctionComponent<IProps> = ({
                                                                  onCancel,
                                                                  visible,
                                                                  selectedUser,
                                                                  entitlementPartner,
                                                                  distinctEntitlements,
                                                                  intl
                                                              }: IProps) => {

    const dispatch = useAppDispatch();
    const { rerenderAfterLanguageChange } = useAppSelector((state) => state.globalStateReducer);
    const [modalFooterButtonVisibility, setModalFooterButtonVisibility] = useState<CancelSubmitButtonVisibility>({
        cancelVisibility: true,
        submitVisible: true
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [languageOptions, setLanguageOptions] = useState<DropDownSelection[]>([]);
    const [dateFormatOptions, setDateFormatOptions] = useState<DropDownSelection[]>([]);
    const [numericFormatOptions, setNumericFormatOptions] = useState<DropDownSelection[]>([]);
    const [updatedPartnerList, setUpdatedPartnerList] = useState([]);

    const [gridApi, setGridApi] = useState<GridApi>(undefined);

    const {TabPane} = Tabs;

    const {Option} = Select;

    const successNotificationMessage = {
        en:'Success',
        de:'Erfolg'
    }

    const createPartnerLinkInput = () => {
        const input : EntitlementPartnerInput[] = entitlementPartner?.map((partner) => {
            const companies: EntitlementCompanyInput[] = partner.companies.map((company) => {
                const newCompany : EntitlementCompanyInput = {companyCode: company.companyCode, companyName: company.companyName, companyId: company.companyId}
                return newCompany;
            });
            const newPartner: EntitlementPartnerInput = {partnerNo : partner.partnerNo, partnerName : partner.partnerName, partnerOu : partner.partnerOu
                , partnerId : partner.partnerId, partnerInDB : partner.partnerInDB, entitlements : partner.entitlements
                , companies
                , category : partner.category, appliedRuleIds : partner.appliedRuleIds};
            return newPartner;
        });
        return input;
    }

     const { data: partnerLinksData, loading: partnerLinksLoading, error: partnerLinksError } = useGetPartnerLinksQuery({
       variables: {
          entitlementPartnerInput: createPartnerLinkInput()
       }, skip: !entitlementPartner || entitlementPartner.length === 0 || !distinctEntitlements || distinctEntitlements.length === 0
     });

     

     if (partnerLinksError)
     {
        message.error("Error loading partner links");
     }

    const onGridReady = (params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    };

    const onTabSelectionChange = (key: string) => {
        if (key === "1") {
            setModalFooterButtonVisibility({cancelVisibility: true, submitVisible: true});
        } else {
            setModalFooterButtonVisibility({cancelVisibility: true, submitVisible: false});
        }
    };

    const getNumericFormat = () => {
        const thousandSeparator = selectedUser.thousandSeparator;
        const decimalSeparator = selectedUser.decimalSeparator;
        return `${thousandSeparator}${decimalSeparator}`;
    }

    const onSettingsSave = (arg) => {

        const getThousandSeparator = () => {
            return arg['numericSeparator'][0]
        }

        const getDecimalSeparator = () => {
            return arg['numericSeparator'][1]
        }


        const userPreferences: SetUserPreferences = {
            userSettingsId: selectedUser.userSettingsId,
            dateFormat: arg["dateFormat"],
            language: arg["language"],
            thousandSeparator: getThousandSeparator(),
            decimalSeparator: getDecimalSeparator(),
            userId: selectedUser.user_id,
            username: selectedUser.uid
        };

        setLoading(true)

        setTimeout(()=> {
            if (loading) {
                setLoading(false);
                notification.error({message: intl.formatMessage({id:'user-preferences-error'})})
            }
        },20_000);

        saveUserFormatPreferences(userPreferences).then(item => {
            selectedUser.dateFormat = userPreferences.dateFormat;
            selectedUser.thousandSeparator = userPreferences.thousandSeparator;
            selectedUser.decimalSeparator = userPreferences.decimalSeparator;
            selectedUser.userSettingsId = userPreferences.userSettingsId;

            if (localStorage.getItem("locale") !== arg["language"]) {
                localStorage.setItem("locale", arg["language"]);
            }

            dispatch(triggerLanguageChange())
        });
    };



    useEffect(()=> {
        if (loading) {
            setLoading(false);
            notification.info({message: successNotificationMessage[localStorage.getItem("locale")]})
            
        }
    },[rerenderAfterLanguageChange])

    useEffect(() => {
        retrieveLanguageOptions().then(setLanguageOptions);
        retrieveDateFormatOptions().then(setDateFormatOptions);
        retrieveNumericFormats().then(setNumericFormatOptions)
    }, [])

    useEffect(() => {
        if (!partnerLinksLoading &&  partnerLinksData)
            {
                   console.log("partnerLinksData returned");
                   console.dir(partnerLinksData);
                   setUpdatedPartnerList(partnerLinksData.getPartnerLinks);
            }
        }, [partnerLinksLoading,partnerLinksData]);

    const onDetailGridReady = (params) => {
        params.api.sizeColumnsToFit();
    };

    

    const partnerDetailGridOptions = useMemo(
        () => {
            console.log("COMPANY_TABLE_HEADERS:", COMPANY_TABLE_HEADERS);
            return {
                localeText: getAgGridLocalization(intl),
                enableRangeSelection: false,
                pagination: true,
                paginationAutoPageSize: false,
                paginationPageSize: 5,
                onGridReady: onDetailGridReady,
                columnDefs: COMPANY_TABLE_HEADERS_WITH_PARTNER_TYPE(intl),
                defaultColDef: {
                    sortable: true,
                    flex: 1,
                    resizable: true,
                },
            };
        },
        []
    );
    
    const getDetailRowData = (params) => {
        console.info("getDetailRowData");
        console.dir(params);
        if (isArray(params.data?.details))
        {
            return params.successCallback(params.data.details)
        }
        return [];
    }


    const detailCellRendererParams = useMemo(
        () => ({
            detailGridOptions: partnerDetailGridOptions,
            getDetailRowData: (params) =>
                getDetailRowData(params),
        }),
        []
    );

    return (
            <Modal
                title={intl.formatMessage({id: "user-profile-modal-title"})}
                onCancel={() => onCancel()}
                open={visible}
                width={"1500px"}
                mask={true}
                destroyOnClose={true}
                footer={[
                    <Button key="kyCancel" onClick={onCancel}
                            hidden={!modalFooterButtonVisibility.cancelVisibility}>
                        {intl.formatMessage({id: "user-profile-modal-cancel"})}
                    </Button>,
                    <Button key="kySubmit" type="primary" onClick={onCancel}
                            hidden={!modalFooterButtonVisibility.submitVisible}>
                        {intl.formatMessage({id: "user-profile-modal-submit"})}
                    </Button>
                ]}
            >
                <Row gutter={24}>
                    <Col md={22} sm={12} xs={24}>
                        <div className="pl15">
                            <h1>{`${intl.formatMessage({id: "user-profile-modal-heading"})} "${selectedUser.firstname} ${selectedUser.lastname}"`}</h1>
                        </div>
                    </Col>
                </Row>

                <Tabs defaultActiveKey={"1"} className="users-tabs ml20" onChange={onTabSelectionChange}>
                    <TabPane tab={`${intl.formatMessage({id: "user-profile-modal-user-details-subheading"})}`}
                             key={"1"}>
                        <Form layout={"vertical"} initialValues=
                            {{
                                "firstname": selectedUser.firstname,
                                "lastname": selectedUser.lastname,
                                "email": selectedUser.email,
                                "username": selectedUser.username,
                                "role": selectedUser.roles,
                                "department": selectedUser.departmentnumber
                            }}>
                            <Row gutter={20} className="pl20">
                                <Col span={6}>
                                    <Form.Item
                                        label={`${intl.formatMessage({id: "survey-master-data-grid-header-firstName"})}`}
                                        name={"firstname"}>
                                        <Input disabled={true}/>
                                    </Form.Item>
                                </Col>

                                <Col span={6}>

                                    <Form.Item
                                        label={`${intl.formatMessage({id: "survey-master-data-grid-header-lastName"})}`}
                                        name={"lastname"}>
                                        <Input disabled={true}/>
                                    </Form.Item>
                                </Col>

                                <Col span={6}>

                                    <Form.Item
                                        label={`${intl.formatMessage({id: "survey-master-data-grid-header-email"})}`}
                                        name={"email"}>
                                        <Input disabled={true}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20} className="pl20">

                                <Col span={6}>
                                    <Form.Item label={`${intl.formatMessage({id: "user-profile-modal-login-name"})}`}
                                               name={"username"}>
                                        <Input disabled={true}/>
                                    </Form.Item>
                                </Col>


                                <Col span={6}>
                                    <Form.Item label={`${intl.formatMessage({id: "user-profile-modal-user-department"})}`}
                                               name={"department"}>
                                        <Input disabled={true}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label={`${intl.formatMessage({id: "user-profile-modal-user-role"})}`}
                                               name={"department"}>
                                        <List
                                            size="small"
                                            bordered={true}
                                            dataSource={selectedUser.roles}
                                            renderItem={(item) => <List.Item>{item}</List.Item>}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Form>

                        <Row gutter={24} className={"pl20"}>
                            <Col span={22}>
                                <div style={{flex: "auto", height: "400px", paddingTop: "10px"}}
                                     className="ag-theme-balham">
                                    <AgGridReact
                                        defaultColDef={{
                                            enableValue: true,
                                            sortable: true,
                                            resizable: true
                                        }}
                                        rowData={updatedPartnerList}
                                        columnDefs={COMPANY_TABLE_HEADERS_WITH_DETAILS(intl)}
                                        localeText={getAgGridLocalization(intl)}
                                        sideBar={false}
                                        rowClass="agGridRows"
                                        suppressMenuHide={true}
                                        debug={false}
                                        pagination={true}
                                        paginationPageSize={10}
                                        suppressRowClickSelection={true}
                                        onGridReady={onGridReady}
                                        masterDetail={true}
                                        detailCellRendererParams={detailCellRendererParams} 
                                        getRowStyle={getRowStyle} 
                                    />
                                </div>
                            </Col>
                        </Row>

                    </TabPane>

                    <TabPane tab={intl.formatMessage({id: 'user-format-preferences-heading'})} key={"2"}>
                        <Spin size={"large"} spinning={loading}>
                            <Form layout={"vertical"} initialValues=
                                {{
                                    "language": localStorage.getItem('locale') || 'en',
                                    "dateFormat": selectedUser.dateFormat,
                                    "numericSeparator": getNumericFormat(),
                                }} onFinish={onSettingsSave}>

                                <Row gutter={20} className="pl20">
                                    <Col span={6}>
                                        <Form.Item
                                            label={`${intl.formatMessage({id: "user-preferences-language-label"})}`}
                                            name={"language"}>
                                            <Select>
                                                {
                                                    languageOptions.map((option, idx) =>
                                                        <Option key={`${idx}`}
                                                                value={option.id}>{intl.formatMessage({id: option.phraseId})}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={20} className="pl20">
                                    <Col span={6}>
                                        <Form.Item
                                            label={`${intl.formatMessage({id: "user-preferences-date-format-label"})}`}
                                            name={"dateFormat"}>
                                            <Select>
                                                {
                                                    dateFormatOptions.map((option, idx) =>
                                                        <Option key={`${idx}`}
                                                                value={option.id}>{option.description}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={20} className="pl20">
                                    <Col span={6}>
                                        <Form.Item
                                            label={`${intl.formatMessage({id: "user-preferences-numeric-separator-label"})}`}
                                            name={"numericSeparator"}>
                                            <Select>
                                                {
                                                    numericFormatOptions.map((option, idx) =>
                                                        <Option key={`${idx}`}
                                                                value={option.id}>{option.description}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={6}>
                                        <Button type="primary" htmlType={"submit"}>
                                            {intl.formatMessage({id: "user-profile-modal-submit"})}
                                        </Button>
                                    </Col>
                                </Row>

                            </Form>
                        </Spin>

                    </TabPane>
                </Tabs>
            </Modal>
    );
};

export default NewUserProfileModal;
