import React, { useEffect, useRef } from 'react';
import {
    DropDownSelectionMode,
    ExternalStateUpdateCallback,
    InfoPortalAgGridColumnDef,
    InfoPortalColumnDef,
    InfoPortalGridService,
    InfoPortalReport,
    InfoPortalSearchProperties,
    IValidator,
    LabelLocation,
    nonePresent,
    OnChangeListener,
    ReportViewConfig,
    stringToDayjs_yyyy_MM_dd,
    utcDateToFormatTo_yyyy_MM_dd_string,
    Validator,
} from '../../../InfoPortalInterfaces';
import { Card, Col, FormInstance, Row } from 'antd';
import {
    BusinessAreaListSelection,
    CompanySelection,
    descriptionFilter,
    PartnerSelection,
    SettlementTypeSelection,
    StatusSelection,
} from '../../InfoPortalDropdowns';
import { RuleSet } from '../../../../e-cap/util/RuleResultCache';
import { IntlShape } from 'react-intl/src/types';
import { retrieveSR2Report } from '../../../services/SR2ReportSearch';
import {
    BMWInvoiceNumber,
    DeliveryNoteNumber,
    PartnerInvoiceNumber,
    PurchaseOrderNumber,
} from '../../InputWithOperator';
import {
    DeliveryNoteFromToSelection,
    GRSCDateFromToSelection,
    PurchaseOrderFromToSelection,
} from '../../FromToDateSelection';
import GoodsReceiptsServiceConfirmationsExpectSearch from './GoodsReceiptsServiceConfirmationsExpectSearch';
import { PortalUser } from '../../../../../components';
import {
    convertToUserSpecifiedNumericFormat,
    dateComparatorUsingUserPreferences,
    getDateFormatOrDefault,
    numericComparatorUsingUserPreferences,
} from '../../../../users/UserFormatPreferenceServices';
import {
    filterParamAmountFormattedAsStringUsingPreferences,
    filterParamWithUserDateFormatComparator
} from "../../grid/GridUtil";


function validatator(form: FormInstance<any>): Validator {
    const validator: IValidator = {
        fetchRule(fieldName: string): RuleSet {
            const result = {
                required: false,
                picture: '',
                readOnly: false,
                hidden: false,
                formatMessage: null,
                infoMessage: null,
            };
            if (fieldName === 'keyDate') {
                result.required = true;
            }
            if (fieldName === 'company') {
                result.required = true;
            }
            if (fieldName === 'partner') {
                result.required = true;
            }
            if (fieldName === 'status') {
                result.required = true;
            }

            return result;
        },
    };
    return new Validator(validator);
}

const GoodsReceiptsServiceConfirmationsSimpleSearch = ({
                                                         currentUser,
                                                         intl,
                                                         reportEntitlement,
                                                         distinctEntitlements,
                                                         initialization,
                                                         form,
                                                         initialValuesCache,
                                                         rerenderDependentFields
                                                       }: InfoPortalSearchProperties) => {
  const vertical = useRef<boolean>(false);

  const validator = validatator(form);
  const partnerSelectionUpdate = new ExternalStateUpdateCallback<{ companyIds: string[], newVal: string[] }>();

  const companySelectionChange: OnChangeListener<string[]> = {
    performAction(companyIds: string[]) {
      const newVal = form.getFieldValue("partner") || [];
      partnerSelectionUpdate.invokeCallBack({ companyIds, newVal });
    }
  };

  useEffect(() => {
    if (rerenderDependentFields) {
      const companyIds = form.getFieldValue("company");
      const newVal = form.getFieldValue("partner");
      partnerSelectionUpdate.invokeCallBack({ companyIds, newVal });
    }
  });

  return <>

    <Card size={"small"} style={{ backgroundColor: "#f1f3f5" }}>
      <Row gutter={26}>
        <Col span={12}>
          <CompanySelection
            selectionMode={DropDownSelectionMode.MULTIPLE}
            labelLocation={vertical.current ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={true}
            currentUser={currentUser}
            initialValue={initialValuesCache?.getValue("company")}
            intl={intl}
            validator={validator}
            distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form}
            onSelectionChange={companySelectionChange}
            filterOptions={descriptionFilter}
            companyEntitlement={reportEntitlement}/>
          <PartnerSelection
            selectionMode={DropDownSelectionMode.MULTIPLE}
            partnerEntitlement={reportEntitlement}
            currentUser={currentUser} intl={intl}
            initialValue={initialValuesCache?.getValue("partner")}
            distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form}
            validator={validator}
            labelLocation={vertical.current ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={true}
            companyIds={initialValuesCache?.getValue("company")}
            externalStateUpdater={partnerSelectionUpdate}
            filterOptions={descriptionFilter} />

          <PartnerInvoiceNumber
            currentUser={currentUser}
            validator={validator}
            labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={!vertical}
            initialInputValue={initialValuesCache?.getValue("partnerInvoiceNumber")}
            initialSelectedOperator={initialValuesCache?.getValueOrDefault("partnerInvoiceOperator", "CP")}
            intl={intl}
            distinctEntitlements={distinctEntitlements}
            initialization={initialization}
            form={form} />
          <SettlementTypeSelection
            selectionMode={DropDownSelectionMode.SINGLE}
            currentUser={currentUser}
            validator={validator}
            labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={!vertical}
            initialValue={initialValuesCache?.getValue("settlementType")}
            intl={intl}
            distinctEntitlements={distinctEntitlements}
            initialization={initialization}
            form={form} />
          <DeliveryNoteNumber
            validator={validator}
            labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={!vertical}
            initialInputValue={initialValuesCache?.getValue("deliveryNoteNumber")}
            initialSelectedOperator={initialValuesCache?.getValueOrDefault("deliveryNoteNumberOperators", "CP")}
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form} />
          <DeliveryNoteFromToSelection
            validator={validator}
            dateFormatter={getDateFormatOrDefault(currentUser)}
            initialValue={initialValuesCache?.getValue("deliveryNoteDate", stringToDayjs_yyyy_MM_dd)}
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form} />

        </Col>
        <Col span={12}>
          <StatusSelection
            initialValue={initialValuesCache?.getValue("status")}
            displayColon={true}
            selectionMode={DropDownSelectionMode.SINGLE}
            currentUser={currentUser}
            intl={intl}
            validator={validator}
            reportName={"SR2"}
            distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form} />

          <BusinessAreaListSelection
            selectionMode={DropDownSelectionMode.MULTIPLE}
            initialValue={initialValuesCache?.getValue("businessAreaCode")}
            displayColon={true}
            currentUser={currentUser}
            intl={intl}
            validator={validator}
            reportName={"SR2"}
            distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form} />
          <BMWInvoiceNumber
            currentUser={currentUser}
            validator={validator}
            labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={!vertical}
            initialInputValue={initialValuesCache?.getValue("bmwInvoiceNumber")}
            initialSelectedOperator={initialValuesCache?.getValueOrDefault("bmwInvoiceNumberOperator", "CP")}
            intl={intl}
            distinctEntitlements={distinctEntitlements}
            initialization={initialization}
            form={form} />
          <PurchaseOrderNumber
            validator={validator}
            labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={!vertical}
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            initialInputValue={initialValuesCache?.getValue("purchaseOrderNumber")}
            initialSelectedOperator={initialValuesCache?.getValueOrDefault("purchaseOrderNumberOperator", "CP")}
            initialization={initialization} form={form} />
          <PurchaseOrderFromToSelection
            validator={validator}
            dateFormatter={getDateFormatOrDefault(currentUser)}
            initialValue={initialValuesCache?.getValue("purchaseOrderDate", stringToDayjs_yyyy_MM_dd)}
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form} />
          <GRSCDateFromToSelection
            validator={validator}
            dateFormatter={getDateFormatOrDefault(currentUser)}
            initialValue={initialValuesCache?.getValue("grScDate", stringToDayjs_yyyy_MM_dd)}
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form} />
        </Col>

      </Row>

    </Card>

  </>;
};

export class GoodsReceiptsAgGridColumnDef implements InfoPortalAgGridColumnDef {
  getDefaultColumnDefinitions(intl: IntlShape,user:PortalUser): InfoPortalColumnDef[] {
    return [
      { field: "companyName", headerName: intl.formatMessage({ "id": "grid-heading-company" }) },
      { field: "partnerNumber", headerName: intl.formatMessage({ "id": "grid-heading-partnerNumber" }) },
      { field: "settlementType", headerName: intl.formatMessage({ "id": "grid-heading-settlementType" }) },
      { field: "grstatus", headerName: intl.formatMessage({ "id": "grid-heading-status", defaultMessage: "Status" }) },
      {
        field: "grScDate",
        headerName: intl.formatMessage({ "id": "grid-heading-grscDate" }),
        valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.grScDate,getDateFormatOrDefault(user)),
        filterParams:filterParamWithUserDateFormatComparator(user),
        filter:'agDateColumnFilter',
        comparator: dateComparatorUsingUserPreferences(user)
      },
      { field: "bmwDocNumber", headerName: intl.formatMessage({ "id": "grid-heading-bmwInvoiceNumber" }) },
      {
        field: "invoiceDate", headerName: intl.formatMessage({ "id": "grid-heading-documentDate" }),
        valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.invoiceDate,getDateFormatOrDefault(user)),
        filterParams:filterParamWithUserDateFormatComparator(user),
        filter:'agDateColumnFilter',
        comparator: dateComparatorUsingUserPreferences(user)
      },
      { field: "partnerInvoiceNumber", headerName: intl.formatMessage({ "id": "grid-heading-partnerInvoiceNumber" }) },
      { field: "purchaseOrderNumber", headerName: intl.formatMessage({ "id": "grid-heading-purchaseOrderNumber" }) },
      {
        field: "purchaseorderdate",
        headerName: intl.formatMessage({ "id": "grid-heading-purchaseOrderDate" }),
        valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.purchaseorderdate,getDateFormatOrDefault(user)),
        filterParams:filterParamWithUserDateFormatComparator(user),
        filter:'agDateColumnFilter',
        comparator: dateComparatorUsingUserPreferences(user)
      },
      { field: "deliveryNoteNumber", headerName: intl.formatMessage({ "id": "grid-heading-deliveryNoteNumber" }) },
      {
        field: "deliveryNoteDate",
        headerName: intl.formatMessage({ "id": "grid-heading-deliveryNoteDate" }),
        valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.deliveryNoteDate,getDateFormatOrDefault(user)),
        filterParams:filterParamWithUserDateFormatComparator(user),
        filter:'agDateColumnFilter',
        comparator: dateComparatorUsingUserPreferences(user)
      },
      { field: "confirmationNumber", headerName: intl.formatMessage({ "id": "grid-heading-confirmationNumber" }) },
      {
        field: "grValue",
        headerName: intl.formatMessage({ "id": "grid-heading-grValue" }),
        type: "rightAligned",
        valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.grValue),
        filter: "agTextColumnFilter",
        filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
        comparator:numericComparatorUsingUserPreferences(user)
      },
      { field: "currency", headerName: intl.formatMessage({ "id": "grid-heading-currency" }) },
      { field: "plantName", headerName: intl.formatMessage({ "id": "grid-heading-plant" }) },
      { field: "materialDocNumber", headerName: intl.formatMessage({ "id": "grid-heading-grscDocumentNumber" }) },
      { field: "businessAreaName", headerName: intl.formatMessage({ "id": "grid-heading-businessArea" }) },
      { field: "documentType", headerName: intl.formatMessage({ "id": "grid-heading-documentType" }) },
      {
        field: "invoiceVerificationContactEmail",
        headerName: intl.formatMessage({ "id": "grid-heading-contactPartnerInvoiceVerification" })
      },

      { field: "recipientName", headerName: intl.formatMessage({ "id": "grid-heading-recipientofgoodsservicesname" }) },
      {
        field: "recipientEmail",
        headerName: intl.formatMessage({ "id": "grid-heading-recipientofgoodsservicesemail" })
      },
      { field: "spabNumber", headerName: intl.formatMessage({ "id": "grid-heading-spabOrderNumber" }) }
    ];
  }

  getShortViewColumns(): string[] {
    return ["companyName",
      "partnerNumber",
      "settlementType",
      "grstatus",
      "grScDate",
      "bmwDocNumber",
      "docdate",
      "partnerInvoiceNumber",
      "purchaseOrderNumber",
      "purchaseorderdate",
      "deliveryNoteNumber",
      "deliveryNoteDate",
      "confirmationNumber",
      "grValue",
      "currency"
    ];
  }

  getLineItemColumnDefinitions(intl: IntlShape,user:PortalUser): InfoPortalColumnDef[] {
    return [
      { field: "grscitem", headerName: intl.formatMessage({ "id": "grid-heading-grscitem" }) },
      { field: "material", headerName: intl.formatMessage({ "id": "grid-heading-materialNumber" }) },
      { field: "materialtext", headerName: intl.formatMessage({ "id": "grid-heading-materialtext" }) },
      {
        field: "grqty",
        headerName: intl.formatMessage({ "id": "grid-heading-grqty" }),
        type: "rightAligned",
        valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.grqty, 3),
        filter: "agTextColumnFilter",
        filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
        comparator:numericComparatorUsingUserPreferences(user)
      },
      { field: "grunit", headerName: intl.formatMessage({ "id": "grid-heading-grunit" }) }

    ];
  }
}

export class SR2Report implements InfoPortalReport {
  getEntitlement(): string {
    return "display_sr@reports";
  }

  private _config = new GoodsReceiptsAgGridColumnDef();

  getColumnsDefinition(): InfoPortalAgGridColumnDef {
    return this._config;
  }

  performSearch(form: FormInstance, currentUser: any, distinctEntitlements: string[]): Promise<InfoPortalGridService> {
    return retrieveSR2Report(form, currentUser, distinctEntitlements).then(result =>
      new InfoPortalGridService(
        new ReportViewConfig("SR2", this._config), result,distinctEntitlements));
  }

  presearch(form: FormInstance<any>, intl: IntlShape): { result: "continue" | "abort-search"; description?: string } {
    if (nonePresent(form, ["purchaseOrderDate", "deliveryNoteDate", "documentDate","grScDate"])) {
      return {
        result: "abort-search",
        description: intl.formatMessage({ id: "sr3-please-select-date" })
      };
    }

    return { result: "continue" };
  }

  hasExpertSearch(): boolean {
    return true;
  }

  renderExpertSearch({
                       currentUser,
                       intl,
                       distinctEntitlements,
                       initialization,
                       form,
                       initialValuesCache
                     }: InfoPortalSearchProperties): JSX.Element {
    return <GoodsReceiptsServiceConfirmationsExpectSearch initialization={initialization}
                                                          currentUser={currentUser}
                                                          intl={intl}
                                                          form={form}
                                                          distinctEntitlements={distinctEntitlements}
                                                          initialValuesCache={initialValuesCache}
                                                          reportEntitlement={this.getEntitlement()}/>;
  }

  renderSimpleSearch({
                       currentUser,
                       intl,
                       distinctEntitlements,
                       initialization,
                       form,
                       initialValuesCache,
                       rerenderDependentFields
                     }: InfoPortalSearchProperties): JSX.Element {
    return <GoodsReceiptsServiceConfirmationsSimpleSearch initialization={initialization}
                                                          currentUser={currentUser}
                                                          intl={intl}
                                                          form={form}
                                                          distinctEntitlements={distinctEntitlements}
                                                          rerenderDependentFields={rerenderDependentFields}
                                                          initialValuesCache={initialValuesCache}
                                                          reportEntitlement={this.getEntitlement()}/>;
  }

  getReportName(): string {
    return "SR2";
  }
}