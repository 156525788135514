import { IServerSideDatasource } from 'ag-grid-enterprise';
import { EIMMonitoringGridRequest } from './interfaces';
import { QUERY_INVOICE_STATUS } from './invotrackQueries';

export default class InvoTrackServerSideDatasource implements IServerSideDatasource {
    private gridOptions: any;
    private client: any;
    private filters: any;

    constructor(gridOptions, client, filters) {
        this.gridOptions = gridOptions;
        this.client = client;
        this.filters = filters;
    }

    getInvoiceStatusRequest(params) {
        const invoiceStatusRequest: EIMMonitoringGridRequest = {
            limit: this.gridOptions.cacheBlockSize,
            offset: params.request.startRow ? params.request.startRow : 0,
            page: params.request.endRow ? params.request.endRow : 1,
            fromDate: this.filters.fromDate,
            toDate: this.filters.toDate,
            direction: this.filters.direction,
            companyGroup: this.filters.companyGroup,

            message_id: !!params.request.filterModel.message_id
                ? params.request.filterModel.message_id.filter
                : this.filters.message_id,
            partnerno: !!params.request.filterModel.partnerno
                ? params.request.filterModel.partnerno.filter
                : this.filters.partnerno,
            company: !!params.request.filterModel.company
                ? params.request.filterModel.company.values
                : this.filters.company,
            sys_id: !!params.request.filterModel.sys_id
                ? params.request.filterModel.sys_id.values
                : this.filters.sys_id,
            reference: !!params.request.filterModel.reference
                ? params.request.filterModel.reference.filter
                : this.filters.reference,
            process: !!params.request.filterModel.process
                ? params.request.filterModel.process.values
                : this.filters.process,
            m_interface: !!params.request.filterModel.m_interface
                ? params.request.filterModel.m_interface.values
                : this.filters.m_interface,
            step_number: !!params.request.filterModel.step_number
                ? params.request.filterModel.step_number.values
                : this.filters.step_number,
            step: !!params.request.filterModel.step ? params.request.filterModel.step.values : this.filters.step,
            status: !!params.request.filterModel.status
                ? params.request.filterModel.status.values
                : this.filters.status,
            cross_border: !!params.request.filterModel.cross_border
                ? params.request.filterModel.cross_border.values
                : this.filters.cross_border,
            status_reason: !!params.request.filterModel.status_reason
                ? params.request.filterModel.status_reason.filter
                : this.filters.status_reason,
            duplicate: !!params.request.filterModel.duplicate
                ? params.request.filterModel.duplicate.values
                : this.filters.duplicate,
            inv_status: this.filters.inv_status,
        };

        return invoiceStatusRequest;
    }

    getRows(params) {
        const invoiceStatusRequest = this.getInvoiceStatusRequest(params);
        const sort = [];

        if (params.request.sortModel.length > 0) {
            for (const sortModel of params.request.sortModel) {
                sort.push({
                    property: sortModel.colId === 'creation_ts' ? 'status.updatetime' : sortModel.colId,
                    direction: sortModel.sort,
                });
            }
        }
        sort.push({
            property: 'message_id',
            direction: 'DESC',
        });
        sort.push({
            property: 'step_number',
            direction: 'ASC',
        });
        invoiceStatusRequest.sort = JSON.stringify(sort);
        this.client
            .query({
                query: QUERY_INVOICE_STATUS,
                variables: {
                    request: invoiceStatusRequest,
                },
                fetchPolicy: 'network-only',
            })
            .then((response) => {
                const rows = (response.data || {}).getEIMInvoices.invoiceStatusesList;
                const lastRowFound = rows?.length >= (response.data || {}).getEIMInvoices?.total;
                const lastRow = lastRowFound ? rows?.length : undefined;

                params.successCallback(rows, (response.data || {}).getEIMInvoices?.total);
            })
            .catch(() => {
                params.failCallback();
            });
    }
}
