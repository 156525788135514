import exceljs from 'exceljs';
import { saveAs } from 'file-saver';
const partnerListColumns = [
    {
        key: 'message_id',
        title: 'UUID',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'partnerno',
        title: 'PARTNERNO',
        width: 120,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'sys_id',
        title: 'SYSTEM',
        width: 80,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'company',
        title: 'COD',
        width: 80,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },

    {
        key: 'bmw_int_reference',
        title: 'REFERENCE',
        width: 150,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },

    {
        key: 'duplicate',
        title: 'DUPE',
        width: 80,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },

    {
        key: 'cross_border',
        title: 'CB',
        width: 50,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'process',
        title: 'PROCESS',
        width: 100,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'm_interface',
        title: 'INTERFACE',
        width: 200,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },

    {
        key: 'step_number',
        title: 'STEP NO',
        width: 80,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'step',
        title: 'STEP',
        width: 100,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'status',
        title: 'STATUS',
        width: 70,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },

    {
        key: 'status_reason',
        title: 'REASON',
        width: 200,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },

    {
        key: 'creation_ts',
        title: 'UPDATETIME',
        width: 100,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'sdi_number',
        title: 'SDI NUMBER',
        width: 150,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'invoice_date',
        title: 'INV DATE',
        width: 150,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'log_status_id',
        title: 'T_LOG_STATUS-LOG_STATUS_ID',
        width: 150,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'log_obj_id',
        title: 'T_LOG_STATUS-LOG_OBJ_ID',
        width: 150,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'ebox_folder',
        title: 'EBOX FOLDER',
        width: 150,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
];

export function exportMonitoringData(monitoringData: object[], fileName?: string): void {
    const options = {
        dateUTC: true, // use utc when rendering dates
        useStyles: true,
        useSharedStrings: true,
    };
    const workbook = new exceljs.Workbook();

    const d = new Date();
    workbook.created = d;
    workbook.modified = d;

    const sheetPartner = workbook.addWorksheet('EINV Monitoring List', options);
    sheetPartner.columns = partnerListColumns.map((col) => ({ header: col.title, key: col.key }));

    for (let i = 0; i < monitoringData.length; i++) {
        sheetPartner.addRow(monitoringData[i]);
    }
    workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {});
        saveAs(
            blob,
            fileName ? (fileName.endsWith('.xlsx') ? fileName : `${fileName}.xlsx`) : 'eInvoicingMonitoringExport.xlsx'
        );
    });
}

const partnerGraphDataListColumns = [
    /*      {
        key: 'year',
        title: 'Year',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },*/
    {
        key: 'month',
        title: 'MONTH',
        width: 120,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'company',
        title: 'Company Code',
        width: 100,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },

    {
        key: 'process',
        title: 'Process',
        width: 200,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'group',
        title: 'BMW Group',
        width: 200,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'partnerno',
        title: 'PartnerNo',
        width: 200,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'total',
        title: 'Total Invoices',
        width: 200,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
];

export function exportGraphData(graphData: object[], fileName?: string): void {
    const options = {
        dateUTC: true, // use utc when rendering dates
        useStyles: true,
        useSharedStrings: true,
    };
    const workbook = new exceljs.Workbook();

    const d = new Date();
    workbook.created = d;
    workbook.modified = d;

    const sheetPartner = workbook.addWorksheet('EINV Monitoring Graph List', options);
    sheetPartner.columns = partnerGraphDataListColumns.map((col) => ({ header: col.title, key: col.key }));

    for (let i = 0; i < graphData.length; i++) {
        sheetPartner.addRow(graphData[i]);
    }

    workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {});
        saveAs(
            blob,
            fileName
                ? fileName.endsWith('.xlsx')
                    ? fileName
                    : `${fileName}.xlsx`
                : 'eInvoicingMonitoringGraphExport.xlsx'
        );
    });
}

const overduePerDateCols = [
    {
        key: 'date',
        title: 'date',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'total',
        title: 'total',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
];

const overduePerProcessCols = [
    {
        key: 'process',
        title: 'process',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'total',
        title: 'total',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
];

const overduePerInterfaceCols = [
    {
        key: 'interface',
        title: 'interface',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'total',
        title: 'total',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
];
export function exportOverdueGraphData(overdueData: any[], fileName?: string): void {
    const options = {
        dateUTC: true, // use utc when rendering dates
        useStyles: true,
        useSharedStrings: true,
    };

    const overdueInvWorkbook = new exceljs.Workbook();

    const d = new Date();
    overdueInvWorkbook.created = d;
    overdueInvWorkbook.modified = d;

    const sheetPerDate = overdueInvWorkbook.addWorksheet('Overdue INV per date', options);
    sheetPerDate.columns = overduePerDateCols.map((col) => ({ header: col.title, key: col.key }));
    sheetPerDate.addRows(overdueData[0].perDate.map((data) => [data.text, data.total]));

    const sheetPerProcess = overdueInvWorkbook.addWorksheet('Overdue INV per process', options);
    sheetPerProcess.columns = overduePerProcessCols.map((col) => ({ header: col.title, key: col.key }));
    sheetPerProcess.addRows(overdueData[0].perProcess.map((data) => [data.text, data.total]));

    const sheetPerInterface = overdueInvWorkbook.addWorksheet('Overdue INV per interface', options);
    sheetPerInterface.columns = overduePerInterfaceCols.map((col) => ({ header: col.title, key: col.key }));
    sheetPerInterface.addRows(overdueData[0].perInterface.map((data) => [data.text, data.total]));

    overdueInvWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {});
        saveAs(
            blob,
            fileName
                ? fileName.endsWith('.xlsx')
                    ? fileName
                    : `${fileName}.xlsx`
                : 'eInvoicing-OverdueGraphExport.xlsx'
        );
    });
}

const failedPerDateCols = [
    {
        key: 'date',
        title: 'date',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'total',
        title: 'total',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
];

const failedPerProcessCols = [
    {
        key: 'process',
        title: 'process',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'total',
        title: 'total',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
];

const failedPerInterfaceCols = [
    {
        key: 'interface',
        title: 'interface',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'total',
        title: 'total',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
];

export function exportFailedGraphData(overdueData: any[], fileName?: string): void {
    const options = {
        dateUTC: true, // use utc when rendering dates
        useStyles: true,
        useSharedStrings: true,
    };

    const failedInvWorkbook = new exceljs.Workbook();

    const d = new Date();
    failedInvWorkbook.created = d;
    failedInvWorkbook.modified = d;

    const sheetPerDate = failedInvWorkbook.addWorksheet('Failed INV per date', options);
    sheetPerDate.columns = failedPerDateCols.map((col) => ({ header: col.title, key: col.key }));
    sheetPerDate.addRows(overdueData[0].perDate.map((data) => [data.text, data.total]));

    const sheetPerProcess = failedInvWorkbook.addWorksheet('Failed INV per process', options);
    sheetPerProcess.columns = failedPerProcessCols.map((col) => ({ header: col.title, key: col.key }));
    sheetPerProcess.addRows(overdueData[0].perProcess.map((data) => [data.text, data.total]));

    const sheetPerInterface = failedInvWorkbook.addWorksheet('Failed INV per interface', options);
    sheetPerInterface.columns = failedPerInterfaceCols.map((col) => ({ header: col.title, key: col.key }));
    sheetPerInterface.addRows(overdueData[0].perInterface.map((data) => [data.text, data.total]));

    failedInvWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {});
        saveAs(
            blob,
            fileName
                ? fileName.endsWith('.xlsx')
                    ? fileName
                    : `${fileName}.xlsx`
                : 'eInvoicing-FailedGraphExport.xlsx'
        );
    });
}
const totalInvPerDateCols = [
    {
        key: 'reference',
        title: 'Reference',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'partnerno',
        title: 'Partnerno',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'status',
        title: 'Status',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'company',
        title: 'Company',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'process',
        title: 'Process',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'group',
        title: 'Group',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },

    {
        key: 'date',
        title: 'Date',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
];
export function exportTotalInvGraphData(totalInvData: any[], fileName?: string): void {
    const options = {
        dateUTC: true, // use utc when rendering dates
        useStyles: true,
        useSharedStrings: true,
    };

    const totalInvWorkbook = new exceljs.Workbook();

    const d = new Date();
    totalInvWorkbook.created = d;
    totalInvWorkbook.modified = d;

    const sheetPerDate = totalInvWorkbook.addWorksheet('Total INV per date', options);
    sheetPerDate.columns = totalInvPerDateCols.map((col) => ({ header: col.title, key: col.key }));
    sheetPerDate.addRows(
        totalInvData.map((data) => [
            data.reference,
            data.partnerno,
            data.status,
            data.company,
            data.process,
            data.group,
            data.date,
        ])
    );

    totalInvWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {});
       saveAs(
            blob,
            fileName ? (fileName.endsWith('.xlsx') ? fileName : `${fileName}.xlsx`) : 'eInvoicing-TotalGraphExport.xlsx'
        );
    });
}

const totlInvPerProcessCols = [
    {
        key: 'process',
        title: 'process',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'total',
        title: 'total',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
];

const totalPerCompanyCols = [
    {
        key: 'company',
        title: 'company',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'total',
        title: 'total',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
];

const totalPerGroupCols = [
    {
        key: 'group',
        title: 'group',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
    {
        key: 'total',
        title: 'total',
        width: 220,
        style: { font: { name: 'Arial Black', bold: true, size: 16 } },
    },
];
export function exportTotalInvGraphDataPerOption(totalInvData: any[], value: string, fileName?: string): void {
    const options = {
        dateUTC: true, // use utc when rendering dates
        useStyles: true,
        useSharedStrings: true,
    };

    const totalInvWorkbook = new exceljs.Workbook();

    const d = new Date();
    totalInvWorkbook.created = d;
    totalInvWorkbook.modified = d;

    switch (value) {
        case 'process':
            const sheetPerProcess = totalInvWorkbook.addWorksheet('Total INV per process', options);
            sheetPerProcess.columns = totlInvPerProcessCols.map((col) => ({ header: col.title, key: col.key }));
            sheetPerProcess.addRows(totalInvData[0].process.map((data) => [data.text, data.total]));
            break;
        case 'company':
            const sheetPerCompany = totalInvWorkbook.addWorksheet('Total INV per company', options);
            sheetPerCompany.columns = totalPerCompanyCols.map((col) => ({ header: col.title, key: col.key }));
            sheetPerCompany.addRows(totalInvData[0].company.map((data) => [data.text, data.total]));
            break;

        case 'group':
            const sheetPerGroup = totalInvWorkbook.addWorksheet('Total INV per group', options);
            sheetPerGroup.columns = totalPerGroupCols.map((col) => ({ header: col.title, key: col.key }));
            sheetPerGroup.addRows(totalInvData[0].group.map((data) => [data.text, data.total]));
            break;
    }

    totalInvWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {});
        saveAs(
            blob,
            fileName
                ? fileName.endsWith('.xlsx')
                    ? fileName
                    : `${fileName}.xlsx`
                : 'eInvoicing-TotalInvGraphExport.xlsx'
        );
    });
}
