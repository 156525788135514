import { IAppComponentProps } from "../../components";
import React, { useEffect, useState } from "react";
import {Button, Col, DatePicker, Form, Row, Select, Space, Tooltip} from "antd";
import { PortalFormItem } from "../../components/FormUtils";
import { useIntl } from "react-intl";
import { useLazyQuery, useQuery } from "@apollo/client";
import { selectOption, selectOptionVariables } from "../users/__generated__/selectOption";
import { SELECT_OPTION_QUERY } from "../users/queries";
import { getI18n } from "../../utils/Utils";
import { PartnerLookup } from "../../components/PartnerLookup";
import { CompanyLookup } from "../../components/CompanyLookup";
import {getRangePickerLocalization} from "../info-portal/components/grid/CalendarUtils";
import {getDateFormatOrDefault} from "../users/UserFormatPreferenceServices";
import {
  getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners
} from "../../main/__generated__/getSelfEntitlementResultByFilter";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import styles from "../info-portal/components/styling/infoportal.module.css";
import documentOverviewStyles from "./styling/document.module.css";

type MyDocumentSearchFormProps = {
  onSearch: any;

} & IAppComponentProps;

const Freight_Entitlement = "display@freight";

const MyDocumentSearchForm: React.FunctionComponent<MyDocumentSearchFormProps> = (props) => {

  const [form] = Form.useForm();
  const intl = useIntl();
  const [orderAreas, setOrderAreas] = useState<selectOption>(undefined);
  const [_partnerNo, setPartnerNo] = useState(undefined);


  const [getOrderArea] = useLazyQuery<selectOption, selectOptionVariables>(SELECT_OPTION_QUERY,
    { fetchPolicy: "network-only", variables: { select: "AREA", username: props.currentUser.username } }
  );


  useEffect(() => {

    if (!orderAreas) {
      getOrderArea().then(response => setOrderAreas(response?.data));
    }

  }, []);

  const onSearch = () => {
    console.dir(form.getFieldsValue());
    props.onSearch(form.getFieldsValue());
  };


  const onClear = () => {
    form.resetFields();
  };

  const { Option } = Select;

  const handlePartnerChange = (value: string) => {
    console.log(`Selected supplier: ${value}`);
    setPartnerNo(value);
  }

  const onlyShowFreightPartners =
      (data: getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners[]) =>
          Array.from(data.filter(item => item?.category === 'Z').values());

  return (
    <>
      <Form size={"middle"} layout={"vertical"} form={form} onFinish={() => onSearch}>
        <div className={documentOverviewStyles.documentOverviewFourComponents}>
          <Form.Item name={"fromDate"} key={"fromDate"} label={getI18n("freight_panel_form_fromDate",
              "Document From Date", intl)}>
            <DatePicker
                format={getDateFormatOrDefault(props.currentUser)}
                placeholder={intl.formatMessage({
                  id: "freight_panel_form_fromDate",
                  defaultMessage: "Document From Date"
                })}
                allowClear={true}
                data-testid={"fromDate"}
                style={{width: "100%"}}
                locale={getRangePickerLocalization(intl, localStorage.getItem("locale"))}
            />
          </Form.Item>
          <PortalFormItem
              label={getI18n("freight_panel_form_reference", "Reference", intl)}
              name={"reference"}
              placeHolder={getI18n("freight_panel_form_reference", "Reference", intl)}/>


          <Form.Item name={"orderArea"} key={"orderAreaKey"}
                     label={getI18n("freight_panel_form_orderArea", "Order Area", intl)}>
            <Select
                placeholder={getI18n("freight_panel_form_orderArea", "Order Area", intl)}
                allowClear={true}
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                }
                optionFilterProp="children"
                showSearch={true}

            >
              {orderAreas && orderAreas.getSelectDropDown &&
                  orderAreas.getSelectDropDown.map((oderAreaItem) => (
                      <Option key={`${oderAreaItem.id}_${oderAreaItem.name}orderArea`} value={oderAreaItem.value}>
                        {oderAreaItem.name}
                      </Option>
                  ))}
            </Select>
          </Form.Item>

          <CompanyLookup
              intl={props.intl}
              currentUser={props.currentUser}
              entitlement={Freight_Entitlement}
              distinctEntitlements={props.distinctEntitlements}
          />
        </div>

        <div className={documentOverviewStyles.documentOverviewFourComponents}>
            <Form.Item name={"dateTo"} key={"dateTo"} label={intl.formatMessage({
              id: "freight_panel_form_toDate",
              defaultMessage: "Document To Date"
            })}>
              <DatePicker
                  format={getDateFormatOrDefault(props.currentUser)}
                  placeholder={getI18n("freight_panel_form_toDate", "Document To Date", intl)}
                  allowClear={true}
                  data-testid={"dateTo"}
                  style={{width: "100%"}}
                  locale={getRangePickerLocalization(intl, localStorage.getItem("locale"))}
              />
            </Form.Item>
          <PartnerLookup
              intl={props.intl}
              entitlement={Freight_Entitlement}
              onChange={handlePartnerChange}
              currentUser={props.currentUser}
              distinctEntitlements={props.distinctEntitlements}
              filter={onlyShowFreightPartners}
              colSpan={5}
          />
            <Form.Item name={"downloaded"} label={intl.formatMessage({
              id: "freight_panel_form_downloaded-status",
              defaultMessage: "Download Status"
            })}>
              <Select
                  showSearch={true}
                  placeholder={intl.formatMessage({
                    id: "freight_panel_form_downloaded-status",
                    defaultMessage: "Download Status"
                  })} allowClear={true}>
                <Select.Option key={"kYesOption"} value="YES">{intl.formatMessage({
                  id: "general-edp-yes",
                  defaultMessage: "Yes"
                })}
                </Select.Option>
                <Select.Option key={"kNoOption"} value="NO">{intl.formatMessage({
                  id: "general-edp-no",
                  defaultMessage: "No"
                })}
                </Select.Option>
              </Select>
            </Form.Item>
          <div>&nbsp;</div>
          </div>
          <div style={{margin: '20px 0px 10px 0px'}}>
            <Tooltip title={intl.formatMessage({id: 'info-portal-search', defaultMessage: 'Search'})}>
              <Button
                  style={{marginRight: '10px'}}
                  data-testid="info-portal-search-button"
                  type="primary"
                  onClick={onSearch}
                  icon={<SearchOutlined/>}
                  size={'large'}
              />
            </Tooltip>

            <Tooltip title={intl.formatMessage({id: 'do-clear', defaultMessage: 'Clear'})}>
              <Button
                  data-testid="info-portal-clear"
                  className={styles.simpleFlipRefresh}
                  type="primary"
                  icon={<ReloadOutlined/>}
                  onClick={onClear}
                  size={'large'}
              />
            </Tooltip>
          </div>
      </Form>
    </>
);
};

export default MyDocumentSearchForm;