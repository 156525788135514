import { Route, Routes } from 'react-router-dom';
import Welcome from '../pages/Welcome';
import EcapWelcome from '../pages/EcapWelcome';
import Users from '../pages/users/Users';
import MyDocuments from '../pages/documents/myDocuments';
import DocumentsOverview from '../pages/documents/DocumentsOverview';
import AccountPayableReports from '../pages/info-portal/components/AccountPayableReports';
import ReportingConfiguration from '../pages/info-portal/components/admin/ReportingConfiguration';
import InternalReports from '../pages/info-portal/components/InternalReports';
import AccountReceivableReports from '../pages/info-portal/components/AccountReceivableReports';
import FsmDocumentOverview from '../pages/documents/FsmDocumentsOverview';
import Assign from '../pages/users/Assign';
import UserProfile from '../pages/users/UserProfile';
import InfoLogMain from '../pages/e-cap/reprocess-info-log/InfoLogMain';
import EntitlementsMain from '../pages/dashboard/entitlement_maintenance/EntitlementsMain';
import Dashboard from '../pages/dashboard/Dashboard';
import SurveyLanding from '../pages/survey/SurveyLandingPage';
import SurveyOverview from '../pages/survey/SurveyOverview';
import SurveyManagement from '../pages/survey/SurveyManagement';
import SurveyMainWizard from '../pages/survey/SurveyMainWizard';
import NotificationsTemplate from '../pages/notifications/notification-management/NotificationsTemplate';
import Notifications from '../pages/notifications/Notifications';
import AddNotification from '../pages/notifications/notification-management/AddNotification';
import BannerManagement from '../pages/notifications/banner/BannerManagement';
import TechMissingInvoices from '../pages/dashboard/missing_invoices/TechMissingInvoices';
import CaptureDocument from '../pages/e-cap/capture-document';
import POFlipDisplay from '../pages/e-cap/po_flip/POFlipSearch';
import TemplateDocumentList from '../pages/e-cap/templates/templateList/TemplateLists-component';
import ManageTemplates from '../pages/e-cap/templates/captureTemplates/capture-template-component';
import DraftDocuments from '../pages/e-cap/draft-documents/DraftDocuments';
import HelpFaqUserGuides from '../pages/help-faq/help-faq-user-guides';
import React from 'react';
import NotificationManagement from '../pages/notifications/notification-management/NotificationManagement';
import MasterDataMain from '../pages/dashboard/MasterDateMaintenance/MasterDataMain';
import InvoiceStatusView from '../pages/eim';

interface IProps {
    intl?: any;
    currentUser?: any;
    distinctEntitlements?: any;
}

const PortalRoutes: React.FC<IProps> = ({ intl, currentUser, distinctEntitlements }: IProps) => {
    return (

            <Routes>
                <Route key={'home'} path="/" element={<Welcome intl={intl} currentUser={currentUser}
                                                               distinctEntitlements={distinctEntitlements} />} />
                <Route key={'welcome'} path="/welcome"
                       element={<Welcome intl={intl} currentUser={currentUser}
                                         distinctEntitlements={distinctEntitlements} />} />
                <Route key={'EcapWelcome'} path="/e-cap-welcome"
                       element={<EcapWelcome intl={intl} currentUser={currentUser}
                                             distinctEntitlements={distinctEntitlements} />} />
                <Route key={'users'} path="/users" element={<Users intl={intl} currentUser={currentUser}
                                                                   distinctEntitlements={distinctEntitlements} />} />
                <Route key={'documents'} path="/documents/transportation"
                       element={<MyDocuments intl={intl} currentUser={currentUser}
                                             distinctEntitlements={distinctEntitlements} />} />
                <Route key={'documentsOverview'} path="/documents/documents-overview"
                       element={<DocumentsOverview intl={intl} currentUser={currentUser}
                                                   distinctEntitlements={distinctEntitlements} />} />
                <Route key={'infoPortal'} path="/reports/account-payable-reports"
                       element={<AccountPayableReports intl={intl} currentUser={currentUser}
                                                       distinctEntitlements={distinctEntitlements} />} />

                <Route key={'infoPortalAdmin'} path="/reports/admin"
                       element={<ReportingConfiguration intl={intl} currentUser={currentUser}
                                                        distinctEntitlements={distinctEntitlements} />} />
                <Route key={'internalReports'} path="/reports/internal-reports"
                       element={<InternalReports intl={intl} currentUser={currentUser}
                                                 distinctEntitlements={distinctEntitlements} />} />
                <Route key={'tnrReports'} path="/reports/tnr-reports"
                       element={<AccountReceivableReports intl={intl} currentUser={currentUser}
                                                          distinctEntitlements={distinctEntitlements} />} />
                <Route key={'fsmDocumentOverview'} path="/reports/fsm-report"
                       element={<FsmDocumentOverview intl={intl} currentUser={currentUser}
                                                     distinctEntitlements={distinctEntitlements} />} />
                <Route key={'assign'} path="/assign"
                       element={<Assign intl={intl} currentUser={currentUser}
                                        distinctEntitlements={distinctEntitlements} />} />
                <Route key={'profile'} path="/profile"
                       element={<UserProfile intl={intl} currentUser={currentUser}
                                             distinctEntitlements={distinctEntitlements} />} />
                <Route key={'infoLog'} path="/documents/info-log"
                       element={<InfoLogMain intl={intl} currentUser={currentUser}
                                             distinctEntitlements={distinctEntitlements} />} />
                <Route key={'entitlements'} path="/entitlements/main"
                       element={<EntitlementsMain intl={intl} currentUser={currentUser}
                                                  distinctEntitlements={distinctEntitlements} />} />
                <Route key={'masterDataMaintenance'} path="/masterData/main"
                       element={<MasterDataMain intl={intl} currentUser={currentUser}
                                                  distinctEntitlements={distinctEntitlements} />} />
                <Route key={'dashboard'} path="/documents/dashboard"
                       element={<Dashboard intl={intl} currentUser={currentUser}
                                           distinctEntitlements={distinctEntitlements} />} />
                <Route key={'surveyLanding'} path={'/survey/survey-landing'}
                       element={<SurveyLanding intl={intl} currentUser={currentUser}
                                               distinctEntitlements={distinctEntitlements} />} />
                <Route key={'surveyOverview'} path={'/survey/survey-overview'}
                       element={<SurveyOverview intl={intl} currentUser={currentUser}
                                                distinctEntitlements={distinctEntitlements} />} />
                <Route key={'surveyManagementOverview'} path={'/survey/survey-management'}
                       element={<SurveyManagement intl={intl} currentUser={currentUser}
                                                  distinctEntitlements={distinctEntitlements} />} />
                <Route key={'surveyMasterData'} path={'/survey/survey-master-data'}
                       element={<SurveyMainWizard intl={intl} currentUser={currentUser}
                                                  distinctEntitlements={distinctEntitlements} />} />
                <Route key={'notificationsTemplate'} path={'/documents/notifications-management/NotificationManagement'}
                       element={<NotificationManagement intl={intl} currentUser={currentUser}
                                                        distinctEntitlements={distinctEntitlements} />} />
                <Route key={'notificationsTemplate'} path={'/documents/notifications-template'}
                       element={<NotificationsTemplate intl={intl} currentUser={currentUser}
                                                       distinctEntitlements={distinctEntitlements} />} />
                <Route key={'surveyNotification'} path={'/notifications/notifications'}
                       element={<Notifications intl={intl} currentUser={currentUser}
                                               distinctEntitlements={distinctEntitlements} />} />
                <Route key={'addNotification'} path={'/documents/add-notification'}
                       element={<AddNotification intl={intl} currentUser={currentUser}
                                                 distinctEntitlements={distinctEntitlements} />} />
                <Route key={'banner-management'} path={'/documents/banner-management'}
                       element={<BannerManagement intl={intl} currentUser={currentUser}
                                                  distinctEntitlements={distinctEntitlements} />} />
                <Route key={'missing-invoices'} path={'/documents/missing-invoices'}
                       element={<TechMissingInvoices intl={intl} currentUser={currentUser}
                                                     distinctEntitlements={distinctEntitlements} />} />
                <Route key={'documentProcessing'} path={'/ecap/document-processing'}
                       element={<CaptureDocument intl={intl} currentUser={currentUser}
                                                 distinctEntitlements={distinctEntitlements} />} />
                <Route key={'documentProcessing'} path={'/e-cap/po-flip'}
                       element={<POFlipDisplay intl={intl} currentUser={currentUser}
                                               distinctEntitlements={distinctEntitlements} />} />
                <Route key={'manageTemplates'} path={'/ecap/manage-templates'}
                       element={<TemplateDocumentList intl={intl} currentUser={currentUser}
                                                      distinctEntitlements={distinctEntitlements} />} />
                <Route key={'templateProcessing'} path={'/ecap/template-processing'}
                       element={<ManageTemplates intl={intl} currentUser={currentUser}
                                                 distinctEntitlements={distinctEntitlements} />} />
                <Route key={'e-cap'} path={'/e-cap/draft-documents'}
                       element={<DraftDocuments intl={intl} currentUser={currentUser}
                                                distinctEntitlements={distinctEntitlements} />} />
                <Route key={'e-cap-help-faq'} path={'/help-faq-user-guides'}
                       element={<HelpFaqUserGuides intl={intl} currentUser={currentUser}
                                                   distinctEntitlements={distinctEntitlements} />} />
                <Route key={'e-cap-help-eim'} path={'/eim'}
                       element={<InvoiceStatusView intl={intl} currentUser={currentUser}
                                                   distinctEntitlements={distinctEntitlements} />} />
            </Routes>)
};

export default PortalRoutes;