import React, {useEffect, useRef, useState} from "react";
import {IAppComponentProps} from "../../../../components";
import {
    BaseInfoPortalReport,
    cacheFieldValues,
    deleteCachedValues,
    DropDownSelectionMode,
    DropDownSelectType,
    FieldValueCache,
    InfoPortalGridService,
    InfoPortalTabConfig,
    Initialization,
    LabelLocation,
    OnChangeListener,
    readCache,
    ReportRegion,
    SubmitSearch
} from "../../InfoPortalInterfaces";
import {Button, Card, Collapse, Form, FormInstance, Modal, Tooltip, Typography} from "antd";
import styles from "../styling/infoportal.module.css";
import {ReloadOutlined, SearchOutlined, ZoomInOutlined, ZoomOutOutlined} from "@ant-design/icons";
import {ReportSelection} from "../InfoPortalDropdowns";
import {InfoPortalDisplayGrid} from "../grid/InfoPortalDisplayGrid";
import {SR3Report} from "../reports/sr3/SR3Report";
import {SR1Report} from "../reports/sr1/SR1Report";
import {LY1Report} from "../reports/ly1/LY1Report";
import {SR5Report} from "../reports/sr5/SR5Report";
import {SR4Report} from "../reports/sr4/SR4Report";
import {getReportTypes} from "../../services/Selectors";
import {SR2Report} from "../reports/sr2/SR2Report";
import {NR3Report} from "../reports/nr3/NR3Report";
import {NR1Report} from "../reports/nr1/NR1Report";
import {NR5Report} from "../reports/nr5/NR5Report";
import {NR4Report} from "../reports/nr4/NR4Report";
import {SR3LightReport} from "../reports/sr3/SR3LightReport";
import {CZ1Report} from "../reports/cz1/CZ1Report";
import {CZ2Report} from "../reports/cz2/CZ2Report";
import {CZ3Report} from "../reports/cz3/CZ3Report";
import {LY2Report} from "../reports/ly2/LY2Report";
import {LY4Report} from "../reports/ly4/LY4Report";
import {LY5Report} from "../reports/ly5/LY5Report";
import {LY6Report} from "../reports/ly6/LY6Report";
import {LY7Report} from "../reports/ly7/LY7Report";
import {useAppSelector} from "../../../../main/hooks";
import {CR1Report} from "../reports/cr1/CR1Report";

type SearchProperties =
    {
        initialization: Initialization,
        infoPortalTab: InfoPortalTabConfig,
        onReportAndSearchTypeChange: (isExpert: boolean, reportName: string) => void,
        registerFormInstance?: (formInstance:FormInstance<any>)=>void
    }
    & IAppComponentProps;

function checkIfTokenHasExpired() {
    const now = new Date();
    const token = localStorage.getItem("id_token");
    const data = JSON.parse(atob(token.split('.')[1]));

    //now.setTime(now.getTime()+(2*60*60*1000));

    if (typeof data['exp'] === "number") {
        const date = new Date(0);
        date.setUTCSeconds(data['exp']);
        console.log(`Comparing ${now} against ${date}`);
        return now > date;
    }

    return false;
}

function displayJwtToken(display:(arg:any)=>void) {
    const now = new Date();
    const token = localStorage.getItem("id_token");
    const data = JSON.parse(atob(token.split('.')[1]));

    function displayDate(key:string) {
        if (typeof data[key] === "number") {
            const date = new Date(0);
            date.setUTCSeconds(data[key]);
            display(`Token attribute ${key}: ${data[key]} and as date [${date}]`);
        }
    }

    function hasExpired() {
        if (typeof data['exp'] === "number") {
            const date = new Date(0);
            date.setUTCSeconds(data['exp']);
            display(`Has expired [${now > date}]`);
        }
    }

    displayDate('exp');
    displayDate('iat');
    display(`Time in UTC seconds: ${now.getTime()} and as date [${now}]`);
    hasExpired();
}

export function InfoPortalTab({
                                  currentUser,
                                  intl,
                                  distinctEntitlements,
                                  initialization,
                                  infoPortalTab,
                                  onReportAndSearchTypeChange,
                                  registerFormInstance,
                              }: SearchProperties) {
    const [isExpertSearch, setExpertSearch] = useState<boolean>(false);
    const [selectedReportType, setSelectedReportType] = useState<string>(infoPortalTab.getInitialSelectedReport());
    const [infoGridService, setInfoGridService] = useState<InfoPortalGridService>(null);
    const [busySearching,setBusySearching] = useState<boolean>(false);
    const [reportTypes, setReportTypes] = useState<{ id: string, description: string, phraseID:string }[]>(null);
    const rerenderDependentComponents = useRef<boolean>(false);
    const formValueCache = useRef<any>({});
    const [form,setForm] = useState<FormInstance<any>>(Form.useForm()[0]);
    const onSubmit = useRef<SubmitSearch>(new SubmitSearch());
    const { rerenderAfterLanguageChange } = useAppSelector((state) => state.globalStateReducer);

    useEffect(()=> {
        if (registerFormInstance) {
            //this is used by tests....
            registerFormInstance(form);
        }
    },[]);

    useEffect(() => {
        getReportTypes(infoPortalTab.getReportRegion(), distinctEntitlements, intl)
            .then(reportTypes => reportTypes.map(reportType => {
                return {id: reportType.id, description: reportType.description, phraseID:reportType.phraseId}
            })).then(setReportTypes);
    }, [rerenderAfterLanguageChange]);

    const resetFields = () => {
        const infoPortalReport = infoPortalTab.searchViews().get(selectedReportType);
        const originalReport = `${selectedReportType}`;
        form.resetFields();
        deleteCachedValues(infoPortalTab.getName(), isExpertSearch, selectedReportType);
        setSelectedReportType(originalReport);

        const resetFields = infoPortalReport.searchResetValues({currentUser, intl, distinctEntitlements});
        form.setFieldsValue({reportType: originalReport, ...resetFields});
    }

    const performSearch = () => {
        displayJwtToken(console.debug);
        const doSearch = () => {
            const infoPortalReport = infoPortalTab.searchViews().get(selectedReportType);
            if (infoPortalReport) {
                const presearch = infoPortalReport.presearch(form, intl);
                if (presearch.result === 'abort-search') {
                    Modal.warning({
                        title: intl.formatMessage({id: "missing-fields",}),
                        content: presearch.description
                    });
                    return Promise.resolve();
                }

                setBusySearching(true);

                return infoPortalReport.performSearch(form, currentUser, distinctEntitlements,intl).then(response => {
                    setInfoGridService(response);
                    cacheFieldValues(form, infoPortalTab.getName(), isExpertSearch, selectedReportType, infoPortalReport.formFieldValueToCacheObjectValueMapper());
                    if (!response?.hasRowData()) {
                        Modal.warning({
                            title: intl.formatMessage({id: "no-results-found",}),
                            content: intl.formatMessage({id: "info-portal-no-results",})
                        });
                    }
                    else {
                        if (response.getNumberOfRows()>=5000) {
                            Modal.warning({
                                title: intl.formatMessage({id: "Download Warning",}),
                                content: intl.formatMessage({id: "info-portal-maximum-results-message",})
                            });
                        }
                    }
                    return response;
                }).catch(error => {
                    console.log(error);
                    const message = error.message;
                    if (message?.includes("status code 405")) {
                        displayJwtToken(console.log);
                        Modal.error({
                            title: intl.formatMessage({id: "Download Error"}),
                            content: checkIfTokenHasExpired()?
                                intl.formatMessage({id: "405-token-has-expired"}):
                                intl.formatMessage({id: "405-error-occurred-while-performing-search"},
                                {companyCode: form.getFieldValue("company"), partnerNo: form.getFieldValue("partner")})
                        });
                    }
                    else {
                        Modal.error({
                            title: intl.formatMessage({id: "Download Error"}),
                            content: intl.formatMessage({id: "error-occurred-while-performing-search"})
                        });
                    }
                }).finally(()=> {
                    setBusySearching(false);
                })
            }

            return Promise.reject('Report type not found');
        }

        onSubmit.current.triggerSearch(doSearch, form);
    }

    function navigateToNextCriteriaView(reportType: string, cache: FieldValueCache) {
        const infoPortalReport = infoPortalTab.searchViews().get(selectedReportType);
        if (!infoPortalReport) {
            return;
        }

        const previouslySelectedView = `${selectedReportType}`;
        formValueCache.current[previouslySelectedView] = {...form.getFieldsValue(), reportType: selectedReportType};
        const newView = `${reportType}`;
        rerenderDependentComponents.current = false;
        if (formValueCache.current[newView]) {
            form.setFieldsValue(formValueCache.current[newView]);
            rerenderDependentComponents.current = true;
        } else {
            if (cache.cacheFound()) {
                form.setFieldsValue(cache.getFormValues(infoPortalReport.cacheObjectValueToFormFieldValueMapper()));
                rerenderDependentComponents.current = true;
            } else {
                form.resetFields();
                form.setFieldsValue({reportType});
            }
        }
    }

    const toggleSearchMode = () => {
        navigateToNextCriteriaView(selectedReportType, readCache(infoPortalTab.getName(), false, selectedReportType));
        setExpertSearch(val => !val);
        onReportAndSearchTypeChange(!isExpertSearch, reportTypes.filter(item => item.id === selectedReportType)[0].phraseID);
    }

    const reportTypeChangeListener: OnChangeListener<string[]> = {
        performAction(val: string[]) {
            navigateToNextCriteriaView(val[0], readCache(infoPortalTab.getName(), false, selectedReportType));
            setSelectedReportType(val[0])
            onReportAndSearchTypeChange(isExpertSearch, reportTypes.filter(item => item.id === val[0])[0].phraseID);
            setInfoGridService(null);
        }
    };

    function displaySimpleSearchView() {
        const simpleCache = readCache(infoPortalTab.getName(), false, selectedReportType);
        const infoPortalReport = infoPortalTab.searchViews().get(selectedReportType);

        if (infoPortalReport) {
            return <div className={`simple-search-${infoPortalReport.getReportName()}`}>
                {infoPortalReport.renderSimpleSearch(
                    {
                        currentUser,
                        intl,
                        distinctEntitlements,
                        form,
                        initialization,
                        initialValuesCache: simpleCache,
                        reportEntitlement:infoPortalReport.getEntitlement(),
                        rerenderDependentFields: rerenderDependentComponents.current
                    })
                }
            </div>
        }

        return <><p>Under construction</p></>
    }

    function displaySelectedReportDescription() {
        const infoPortalReport = infoPortalTab.searchViews().get(selectedReportType);
        const description = infoPortalReport.reportHeaderDescription(intl);
        if (description) {
            return <Collapse items={[
                {
                    key: `${selectedReportType}-heading-description`,
                    label: intl.formatMessage({id: 'Show-Description'}),
                    children: description.split('\\n')
                        .map((line, idx) => <Typography.Title level={5} key={`paragraph-idx-${idx}`}>
                            {line}
                        </Typography.Title>)
                }
            ]}/>
        }

        return <></>
    }

    function displayExpertSearchView() {
        const expertCache = readCache(infoPortalTab.getName(), true, selectedReportType);
        const infoPortalReport = infoPortalTab.searchViews().get(selectedReportType);

        if (infoPortalReport) {
            return <div className={`simple-expert-${infoPortalReport.getReportName()}`}>
                {infoPortalReport.renderExpertSearch(
                    {
                        currentUser,
                        intl,
                        distinctEntitlements,
                        form,
                        initialization,
                        initialValuesCache: expertCache,
                        reportEntitlement:infoPortalReport.getEntitlement(),
                        rerenderDependentFields: rerenderDependentComponents.current
                    })
                }
            </div>
        }

        return <><p>Under construction</p></>
    }

    function displaySearchButtons() {
        const infoPortalReport = infoPortalTab.searchViews().get(selectedReportType);

        if (!infoPortalReport) {
            return <></>
        }

        function hideExpertSearch() {
            return !infoPortalReport.hasExpertSearch();
        }

        if (!isExpertSearch) {
            return <div className={styles.simpleSearchButtons}>
                <Tooltip title={intl.formatMessage({'id': 'info-portal-search', 'defaultMessage': "Search"})}>

                    <Button
                        data-testid="info-portal-search-button"
                        type="primary" icon={<SearchOutlined/>} size={'large'} onClick={performSearch}/>
                </Tooltip>

                <Tooltip
                    title={intl.formatMessage({'id': 'info-portal-expert-search', 'defaultMessage': "Expert Search"})}>
                    <Button
                        data-testid="info-portal-toggle-simple-expert"
                        hidden={hideExpertSearch()}
                        style={{display: hideExpertSearch() ? 'none' : 'inline-block'}}
                        type="primary"
                        icon={<ZoomInOutlined/>}
                        size={'large'} onClick={(e) => toggleSearchMode()}/>
                </Tooltip>

                <Tooltip title={intl.formatMessage({'id': 'do-clear', 'defaultMessage': "Clear"})}>
                    <Button
                            data-testid="info-portal-clear"
                            className={styles.simpleFlipRefresh}
                            type="primary"
                            icon={<ReloadOutlined/>}
                            onClick={(e) => resetFields()}
                            size={'large'}/>
                </Tooltip>
            </div>
        }

        return <div className={styles.simpleSearchButtons}>
            <Tooltip title={intl.formatMessage({'id': 'info-portal-search', 'defaultMessage': "Search"})}>
                <Button
                    data-testid="info-portal-search-button"
                    type="primary" icon={<SearchOutlined/>}
                    size={'large'}
                    onClick={performSearch}/>
            </Tooltip>

            <Tooltip
                title={intl.formatMessage({'id': 'info-portal-simple-search', 'defaultMessage': "Simple Search"})}>
                <Button
                    data-testid="info-portal-toggle-simple-expert"
                    type="primary"
                    icon={<ZoomOutOutlined/>}
                    size={'large'}
                    onClick={(e) => toggleSearchMode()}/>
            </Tooltip>

            <Tooltip title={intl.formatMessage({'id': 'do-clear', 'defaultMessage': "Clear"})}>
                <Button className={styles.simpleFlipRefresh}
                        data-testid="info-portal-clear"
                        type="primary"
                        icon={<ReloadOutlined/>}
                        onClick={(e) => resetFields()}
                        size={'large'}/>
            </Tooltip>
        </div>;
    }

    function displayGrid() {
        return <div data-testid={busySearching? 'info-portal-tab-is-busy-searching':'info-portal-tab-finished-searching'}>
            {infoGridService && <InfoPortalDisplayGrid
                infoGridService={infoGridService}
                intl={intl} currentUser={currentUser}
                distinctEntitlements={distinctEntitlements}/>}
        </div>
    }

    function displayExpertOrSimpleSearch() {
        if (!isExpertSearch) {
            return <div style={{paddingBottom: "20px"}}>

                {
                    /**
                     * Displays the heading for the report
                     */
                    displaySelectedReportDescription()
                }

                <div className={`${styles.selectReportTypeView}`}>
                    <Card size={"small"} className={styles.reportTypeDropdown} style={{backgroundColor: "#f1f3f5"}}>
                        <ReportSelection
                            currentUser={currentUser}
                            intl={intl}
                            distinctEntitlements={distinctEntitlements}
                            initialization={initialization}
                            selectionType={DropDownSelectType.RADIO}
                            selectionMode={DropDownSelectionMode.SINGLE}
                            labelLocation={LabelLocation.LEFT}
                            reportLabel={infoPortalTab.getTabLabel? infoPortalTab.getTabLabel():null}
                            form={form}
                            region={infoPortalTab.getReportRegion()}
                            onSelectionChange={reportTypeChangeListener}
                            initialValue={selectedReportType}/>
                    </Card>

                    <div className={styles.bmwGeneralSimpleSearch}>
                        {displaySimpleSearchView()}
                    </div>
                </div>

                {displaySearchButtons()}

                {displayGrid()}

            </div>
        }

        return <div style={{paddingBottom: "20px"}}>

            {
                /**
                 * Displays the heading for the report
                 */
                displaySelectedReportDescription()
            }

            <div className={styles.bmwGeneralSimpleSearch}>
                {displayExpertSearchView()}
            </div>

            {displaySearchButtons()}

            {displayGrid()}
        </div>
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 22 },
            sm: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 22 },
            sm: { span: 12 }
        }
    };

    const onFinish = ()=> {
        const id = initialization.beginInitialize();
        onSubmit.current.performSearch()
            .finally(() => initialization.finishInitialize(id));
    }

    return <div data-testid={`reportRegion-${infoPortalTab.getReportRegion()}-${selectedReportType}`}>
        <Form
            size={"middle"}
            onFinish={onFinish}
            form={form}
            labelWrap={true}
            labelAlign={"right"}
            {...formItemLayout}>
            {displayExpertOrSimpleSearch()}
        </Form>
    </div>
}

export class BMWMexico implements InfoPortalTabConfig {

    getEntitlement(): string {
        return "display_ly@reports";
    }

    getInitialSelectedReport(): string {
        return '1';
    }

    getName(): string {
        return "BMW_MEXICO";
    }

    getReportRegion(): ReportRegion {
        return ReportRegion.BMW_MEXICO;
    }

    searchViews(): Map<string, BaseInfoPortalReport> {
        const map = new Map<string, BaseInfoPortalReport>();
        map.set('1', new BaseInfoPortalReport(new LY1Report()));
        map.set('2', new BaseInfoPortalReport(new LY2Report()));
        map.set('3', new BaseInfoPortalReport(new LY4Report()));
        map.set('4', new BaseInfoPortalReport(new LY5Report()));
        map.set('5', new BaseInfoPortalReport(new LY6Report()));
        map.set('6', new BaseInfoPortalReport(new LY7Report()));
        return map;
    }
}

export class BMWAGTab implements InfoPortalTabConfig {
    getEntitlement(): string {
        return "display_sr@reports";
    }

    getInitialSelectedReport(): string {
        return '3';
    }

    getName(): string {
        return "BMW_AG";
    }

    getReportRegion(): ReportRegion {
        return ReportRegion.BMW_GENERAL;
    }

    searchViews(): Map<string, BaseInfoPortalReport> {
        const map = new Map<string, BaseInfoPortalReport>();
        map.set('3', new BaseInfoPortalReport(new SR3Report()));
        map.set("1", new BaseInfoPortalReport(new SR1Report()));
        map.set("2", new BaseInfoPortalReport(new SR2Report()));
        map.set("5", new BaseInfoPortalReport(new SR5Report()));
        map.set('4', new BaseInfoPortalReport(new SR4Report()));
        map.set('6', new BaseInfoPortalReport(new SR3LightReport()));
        return map;
    }
}

export class BMWNSCTab implements InfoPortalTabConfig {
    getEntitlement(): string {
        return "display_nr@reports";
    }

    getInitialSelectedReport(): string {
        return '3';
    }

    getName(): string {
        return "BMW_NSC";
    }

    getReportRegion(): ReportRegion {
        return ReportRegion.BMW_NSC;
    }

    searchViews(): Map<string, BaseInfoPortalReport> {
        const map = new Map<string, BaseInfoPortalReport>();
        map.set('3', new BaseInfoPortalReport(new NR3Report()));
        map.set("1", new BaseInfoPortalReport(new NR1Report()));
        map.set("5", new BaseInfoPortalReport(new NR5Report()));
        map.set('4', new BaseInfoPortalReport(new NR4Report()));
        return map;
    }
}

export class BMWChinaTab implements InfoPortalTabConfig {
    getEntitlement(): string {
        return "display_cz@reports";
    }

    getInitialSelectedReport(): string {
        return '1';
    }

    getName(): string {
        return "BMW_CHINA";
    }

    getReportRegion(): ReportRegion {
        return ReportRegion.BMW_CHINA;
    }

    searchViews(): Map<string, BaseInfoPortalReport> {
        const map = new Map<string, BaseInfoPortalReport>();
        map.set('1', new BaseInfoPortalReport(new CZ1Report()));
        map.set('2', new BaseInfoPortalReport(new CZ2Report()));
        map.set('3', new BaseInfoPortalReport(new CZ3Report()));
        return map;
    }
}

export class BMWCORTab implements InfoPortalTabConfig {
    getEntitlement(): string {
        return "display_coa@reports";
    }

    getInitialSelectedReport(): string {
        return '1';
    }

    getName(): string {
        return "BMW_COR";
    }

    getReportRegion(): ReportRegion {
        return ReportRegion.BMW_GENERAL_COR;
    }

    searchViews(): Map<string, BaseInfoPortalReport> {
        const map = new Map<string, BaseInfoPortalReport>();
        map.set("1", new BaseInfoPortalReport(new CR1Report()));
        return map;
    }

    getTabLabel(): string {
        return 'cor-report-select'
    }
}