import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners } from "../../main/__generated__/getSelfEntitlementResultByFilter";
import { DESTROY_SESSION } from "./IStoredDocumentRecordSlice";
import { EcapEntitlementPartner } from '../../__generated__/types';

interface PartnerState {
  partnerRecord: EcapEntitlementPartner;
}

const initialState: PartnerState = {
  partnerRecord: {__typename:"EcapEntitlementPartner",partnerId: undefined, partnerInDB: undefined,systemCode: undefined, partnerName: undefined,
   partnerNo: undefined, partnerOu:undefined, entitlements: []  , companies: [], category: undefined, appliedRuleIds: []}
};

const partnerSlice = createSlice({
  name: "partnerRecord",
  initialState,
  reducers: {
    updateSelectedPartner(state, action: PayloadAction<EcapEntitlementPartner>) {
      const partnerRecord = action.payload;
      state.partnerRecord = partnerRecord;
    },
    destroyPartnerState(state, action: PayloadAction<string>) {
      if (action.payload === DESTROY_SESSION) {
        state.partnerRecord = initialState.partnerRecord;
      }
    }
  }
});

export const { updateSelectedPartner, destroyPartnerState } = partnerSlice.actions;
export default partnerSlice.reducer;