import { gql } from '@apollo/client';

export const QUERY_INVOICE_FILTERS = gql`
    query messagesFilters{
        getAllMessagesFilters{
            company {
                text
                value
            }
            process {
                text
                value
            }
            m_interface {
                text
                value
            }
            status {
                text
                value
            }
            step_number {
                text
                value
            }
            step {
                text
                value
            }
            duplicate {
                text
                value
            }
            sys_id {
                text
                value
            }
            group {
                text
                value
            }
        }
    }
    
`;

export const QUERY_INVOICE_STATUS = gql`
    query GET_InvoicesStatuses($request: EIMMonitoringGridRequest!) {
          getEIMInvoices(request: $request) {
            invoiceStatusesList {
                id
                message_id
                partnerno
                company
                reference
                bmw_int_reference
                duplicate
                cross_border
                process
                m_interface
                step_number
                step
                status
                status_reason
                creation_ts
                sdi_number
                invoice_date
                log_obj_id
                log_status_id
                edi_agreement_date
                sys_id
                ebox_folder
            }
            total
        }
    }


`;